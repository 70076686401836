import {
  signalConfirmCallback,
  signalConfirmOptions,
  signalConfirmShow,
} from '../../../state/app';
import { confirmModalClose } from '../../../state/app-functions';
import { Button, Modal } from 'react-bootstrap';

const ConfirmModal = () => {
  const handleClose = () => {
    confirmModalClose();
  };

  const handleConfirm = () => {
    confirmModalClose();
    signalConfirmCallback.value();
  };

  return (
    <Modal
      show={signalConfirmShow.value}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{signalConfirmOptions.value?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{signalConfirmOptions.value?.body}</Modal.Body>
      <Modal.Footer>
        {/* Cancel button */}
        <Button variant="link" className="me-2" onClick={handleClose}>
          {signalConfirmOptions.value?.cancelText}
        </Button>

        {/* Confirm button */}
        <Button
          variant={signalConfirmOptions.value?.confirmVariant || 'primary'}
          onClick={handleConfirm}
        >
          {signalConfirmOptions.value?.confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
