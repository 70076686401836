import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { saveAnswer, showNextSlide } from '../../../utils/home/lesson';
import { toastMessage } from '../../../state/app-functions';
import Answer from '../Answer/Answer';
import RippleButton from '../../Shared/RippleButton/RippleButton';
import ButtonAnswerText from './ButtonAnswerText';
import { signalLessonData, signalUserId } from '../../../state/app';
import { handleApiError } from '../../../utils/api';

import './style.scss';

const TextInput = ({ slide }) => {
  const [hasAnswered, setHasAnswered] = useState(slide.hasAnswered);

  const { register, getValues } = useForm({
    defaultValues: { text: slide.answers === null ? '' : slide.answers[0] },
  });

  const handleOnClick = async () => {
    let svar = getValues('text').trim();

    if (svar.length === 0) {
      toastMessage('Vänligen svara på frågan');
      return;
    }

    slide.answers = [`${svar}`];

    if (signalLessonData.value.isPreview) {
      toastMessage(
        'Ditt svar är inte sparat eftersom detta är en förhandsvisning'
      );
      setHasAnswered(true);
      showNextSlide(slide, true);
      return;
    }

    try {
      await saveAnswer(signalUserId.value, slide);
    } catch (error) {
      handleApiError(error, 'Ett fel uppstod när ditt svar skulle sparas');
      return;
    }

    setHasAnswered(true);
    showNextSlide(slide, true);
  };

  return (
    <div className="component-fraga-text mb-1">
      <Form.Control
        as="textarea"
        className="input-textarea"
        {...register('text')}
        rows={3}
        disabled={hasAnswered}
      />

      <RippleButton
        className="btn btn-lg btn-secondary w-100 mt-2"
        onClick={handleOnClick}
        disabled={hasAnswered}
      >
        <ButtonAnswerText hasAnswered={hasAnswered}>Svara</ButtonAnswerText>
      </RippleButton>

      <Answer slide={slide} hasAnswered={hasAnswered} />
    </div>
  );
};

export default TextInput;
