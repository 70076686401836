import { apiClient, handleApiError } from '../../../utils/api';
import { userTemplate } from '../../../utils/admin/user.utils';
import { toastMessage } from '../../../state/app-functions';
import { getCustomerById } from '../Customers/utils';
import {
  signalEditedUser,
  signalSelectedUserId,
  signalUsers,
} from '../../../state/admin-users';

export async function getUsers() {
  try {
    const result = await apiClient.get(`users`);
    signalUsers.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta användare');
  }
}

export function getSelectedUser() {
  return signalUsers.value.find(
    (user) => user.id === signalSelectedUserId.value
  );
}

export function getUserById(userId) {
  return signalUsers.value.find((user) => user.id === userId);
}

export async function saveUser() {
  const edit = signalEditedUser.value;

  const body = {
    ...userTemplate,
    name: edit.name,
    email: edit.email,
    password: edit.password || '',
    customers: edit.customers,
    isActive: edit.isActive,
  };

  console.log('save user', body);

  try {
    if (edit.id !== userTemplate.id) {
      // Update
      const resultUpdate = await apiClient.put(`user/${edit.id}`, body);
      console.log('update', resultUpdate.data);
    } else {
      // Create
      const resultCreate = await apiClient.post(`user`, body);
      console.log('user created', resultCreate.data);

      signalEditedUser.value = resultCreate.data;
    }

    await getUsers(); // Reload

    toastMessage('Användaren sparad');
  } catch (error) {
    handleApiError(error, 'Användaren kunde inte sparas');
  }
}

export async function deleteUser() {
  const edit = signalEditedUser.value;

  try {
    await apiClient.delete(`user/${edit.id}`);
    signalEditedUser.value = null;

    await getUsers(); // Reload

    toastMessage('Användaren raderad');
  } catch (error) {
    handleApiError(error, 'Användaren kunde inte raderas');
  }
}

export function getUserCourses(state, stateCourses) {}

export function getUserCustomersAsObject() {
  const edit = signalEditedUser.value;

  const customers = edit.customers.map((customerid) => {
    const customer = getCustomerById(customerid);
    return {
      label: customer.name,
      value: customer.id,
    };
  });

  if (customers.length === 0) return null;

  return customers;
}
