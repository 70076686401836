import React, { useState, useEffect } from 'react';
import { Carousel, Row, Col, Spinner } from 'react-bootstrap';
import { signalLessonData, signalUserId } from '../../state/app';
import { decode } from 'js-base64';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';
import { getLessonData, showNextSlide } from '../../utils/home/lesson';
import Slide from '../Slide/Slide';

import './style.scss';

let userId = '7c63a6c3-1085-4c83-b683-a4a01204c9af';
let courseId = 'dbed260b-bfbe-4029-91af-fd8f0f88cc8c';
let lessonId = 'ab6cdfe5-3fe9-45ea-a9e4-a8b9cfc16269';

const Lesson = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const location = useLocation();
  const [lessonPreview] = useLocalStorage('lessonPreview', null);

  const locationState = location.state;

  useEffect(() => {
    const loadCustomerStyle = () => {
      if (lessonPreview) return; // No style for lesson preview

      const styleSession = sessionStorage.getItem('css');
      if (!styleSession) return;

      console.log('Using customer style', styleSession);

      // Create customer style-element in <head>
      const style = document.createElement('style');
      style.setAttribute('data-customerstyle', '1');
      style.innerHTML = decode(styleSession);
      document.head.appendChild(style);
    };

    const loadLesson = async () => {
      signalLessonData.value = await getLessonData(
        userId,
        courseId,
        lessonId,
        lessonPreview
      );
    };

    if (locationState) {
      userId = locationState.userId;
      courseId = locationState.courseId;
      lessonId = locationState.lessonId;
    }
    signalUserId.value = userId;

    loadCustomerStyle();
    loadLesson();
  }, [lessonPreview, locationState]);

  // useEffect(() => {
  //   if (isDone && !data.started) {
  //     startLesson(userId, lessonId);
  //   }
  // }, [isDone]);

  const handleSelect = (selectedIndex, e) => {
    setCarouselIndex(selectedIndex);
  };

  const handleSlid = (index) => {
    // What to do when new slide is shown
    const slide = signalLessonData.value.instancedQuestions[index];
    showNextSlide(slide);
  };

  return (
    <>
      {!signalLessonData.value && (
        <div>
          <Spinner animation="grow" />
        </div>
      )}

      {/* Lesson */}
      {signalLessonData.value && (
        <div id="page-lesson">
          {/* Frågor */}
          <Carousel
            variant="dark"
            interval={null}
            wrap={false}
            activeIndex={carouselIndex}
            onSelect={handleSelect}
            onSlid={handleSlid}
            keyboard={false}
          >
            {signalLessonData.value.instancedQuestions?.map(function (
              slide,
              index
            ) {
              return (
                <Carousel.Item key={index}>
                  <Row>
                    <Col>{/* Left Arrow */}</Col>
                    <Col xs={8}>
                      {/* Content */}
                      <Slide slide={slide} />
                    </Col>
                    <Col>{/* Right Arrow */}</Col>
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default Lesson;
