import React, { useState } from 'react';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { Button, Form, Offcanvas, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { questionTemplate } from '../../../utils/admin/course.utils';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import Question from './Question/Question';
import {
  deleteQuestion,
  getSelectedLesson,
  saveQuestion,
  updateLessonQuestions,
} from '../Courses/utils';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';
import {
  faArrowsDownToLine,
  faArrowsUpToLine,
  faCopy,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { signalEditedQuestion } from '../../../state/admin-courses';

const QuestionModal = ({ onModalClose }) => {
  const editedObject = signalEditedQuestion.value;
  const selectedLesson = getSelectedLesson();

  let editedObjectIndex = selectedLesson.questions.findIndex(
    (id) => id === editedObject.id
  );

  const [renderCount, setRenderCount] = useState(0);

  const showModal = useSignal(true);
  const objectName = useSignal(editedObject.name);
  const objectActive = useSignal(editedObject.isActive);
  const objectDescription = useSignal(editedObject.description);

  useSignalEffect(() => {
    setRenderCount((prev) => prev + 1);
    editedObject.name = objectName.value;
    editedObject.isActive = objectActive.value;
    editedObject.description = objectDescription.value;
  });

  const closeModal = () => {
    showModal.value = false;
    onModalClose();
  };

  const handleCloseModal = () => {
    if (renderCount > 1 || editedObject.isDirty) {
      confirmModal(closeModal);
      return;
    }

    closeModal();
  };

  // Save
  const handleSave = () => {
    saveQuestion();
    closeModal();
  };

  // Delete
  const handleDelete = () => {
    confirmModal(() => {
      deleteQuestion();
      closeModal();
    }, confirmModalOptionsDelete);
  };

  // Move up/down
  const handleMove = (action) => () => {
    let newIndex;

    switch (action) {
      case 'up':
        newIndex = editedObjectIndex - 1;
        break;
      case 'down':
        newIndex = editedObjectIndex + 1;
        break;
      case 'top':
        newIndex = 0;
        break;
      case 'end':
        newIndex = selectedLesson.questions.length - 1;
        break;
      default:
        break;
    }

    const questions = [...selectedLesson.questions];
    const temp = questions[editedObjectIndex];
    questions[editedObjectIndex] = questions[newIndex];
    questions[newIndex] = temp;
    editedObjectIndex = newIndex;

    selectedLesson.questions = questions;

    updateLessonQuestions(selectedLesson);
  };

  // Duplicate question
  const handleDuplicate = () => {
    const newQuestion = { ...editedObject };
    newQuestion.id = questionTemplate.id;
    newQuestion.name += ' - Kopia';

    delete newQuestion.attachmentId;

    saveQuestion(newQuestion);
  };

  return (
    <div id="component-offcanvas-question">
      {/* Question Offcanvas */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="bottom"
        className="modal-question-height"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              {/* Name */}
              <EditableContent
                className="me-4"
                value={objectName.value}
                onChange={(text) => {
                  objectName.value = text;
                }}
              />

              {/* Aktiv */}
              <Form.Check
                type="switch"
                id="aktiv-switch"
                className={objectActive.value ? 'text-success' : 'text-muted'}
                label={objectActive.value ? 'Aktiv' : 'Inaktiv'}
                checked={objectActive.value}
                onChange={(e) => {
                  objectActive.value = e.target.checked;
                }}
              />

              {/* Actions */}
              {editedObject.id !== questionTemplate.id && (
                <Dropdown>
                  <Dropdown.Toggle variant="secondary"></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleDuplicate}>
                      <FontAwesomeIcon icon={faCopy} className="pe-2" />
                      Duplicera
                    </Dropdown.Item>
                    {editedObjectIndex > 0 && (
                      <>
                        <Dropdown.Item onClick={handleMove('top')}>
                          <FontAwesomeIcon
                            icon={faArrowsUpToLine}
                            className="pe-2"
                          />
                          Flytta överst
                        </Dropdown.Item>

                        <Dropdown.Item onClick={handleMove('up')}>
                          <FontAwesomeIcon
                            icon={faLongArrowAltUp}
                            className="pe-2"
                          />
                          Flytta upp
                        </Dropdown.Item>
                      </>
                    )}
                    {editedObjectIndex <
                      selectedLesson.questions.length - 1 && (
                      <>
                        <Dropdown.Item onClick={handleMove('down')}>
                          <FontAwesomeIcon
                            icon={faLongArrowAltDown}
                            className="pe-2"
                          />
                          Flytta ned
                        </Dropdown.Item>

                        <Dropdown.Item onClick={handleMove('end')}>
                          <FontAwesomeIcon
                            icon={faArrowsDownToLine}
                            className="pe-2"
                          />
                          Flytta nederst
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {/* Save Button */}
              <Button variant="primary" className="ms-4" onClick={handleSave}>
                Spara
              </Button>

              {/* Delete Button */}
              {editedObject.id !== questionTemplate.id && (
                <Button
                  variant="danger"
                  className="ms-4"
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Question Information */}
          <Question />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default QuestionModal;
