import { apiClient, handleApiError } from '../../../utils/api';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import { toastMessage } from '../../../state/app-functions';
import { saveCustomerMetadata } from './utils.metadata';
import {
  signalCustomers,
  signalEditedCustomer,
  signalSelectedCustomerId,
} from '../../../state/admin-customers';

export async function getCustomers() {
  try {
    const result = await apiClient.get(`customers`);
    signalCustomers.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta kunder');
  }
}

export function getSelectedCustomer() {
  if (signalSelectedCustomerId.value === customerTemplate.id) {
    return { ...customerTemplate };
  }

  return signalCustomers.value.find(
    (customer) => customer.id === signalSelectedCustomerId.value
  );
}

export function setSelectedCustomer(customerId) {
  signalSelectedCustomerId.value = customerId;
}

export function getCustomerById(customerId) {
  const customer = signalCustomers.value.find(
    (customer) => customer.id === customerId
  );

  const clone = { ...customer };

  // Metadata
  if (typeof clone.metaData === 'string') {
    clone.metaData = JSON.parse(clone.metaData);

    fixLegacyMetadata(clone.metaData);

    // Add changes/updates to metadata
    clone.metaData = { ...customerTemplate.metaData, ...clone.metaData };
  } else {
    // No metadata -> Add default
    clone.metaData = { ...customerTemplate.metaData };
  }

  console.log('getCustomerById', clone);
  return clone;
}

function fixLegacyMetadata(metaData) {
  // Fonts
  if (!metaData.font.externalResourceId) metaData.font.externalResourceId = [];
  if (typeof metaData.font.externalResourceId === 'string')
    metaData.font.externalResourceId = [metaData.font.externalResourceId];
}

export async function getCustomerCourses() {
  const selectedCustomer = getSelectedCustomer();
  // console.log('getCustomerCourses', selectedCustomer);

  if (!selectedCustomer || !selectedCustomer.availibleCourses) return [];

  const promises = selectedCustomer.availibleCourses.map(
    (instancedCourseGuid) => {
      //console.log(`instancedcourses/${instancedCourseGuid}`);
      return apiClient.get(`instancedcourses/${instancedCourseGuid}`);
    }
  );

  try {
    let instancedCourses = await Promise.all(promises);
    instancedCourses = instancedCourses.map((course) => course.data);
    console.log('instancedCourses', instancedCourses);
    return instancedCourses || [];
  } catch (error) {
    handleApiError(error, 'Kundens kurser kunde inte hämtas');
  }
}

export async function saveCustomer() {
  const edit = signalEditedCustomer.value;

  await saveCustomerMetadata();

  const body = {
    ...customerTemplate,
    name: edit.name,
    isActive: edit.isActive,
    organizationNumber: edit.organizationNumber,
    approvedEmailSuffixes: edit.approvedEmailSuffixes,
    metaData: JSON.stringify(edit.metaData),
  };

  console.log('save/update customer body', body);

  try {
    if (edit.id !== customerTemplate.id) {
      // Update

      // Add "changed" properties
      body.nameChanged = true;
      body.isActiveChanged = true;
      body.organizationNumberChanged = true;
      body.approvedEmailSuffixesChanged = true;
      body.metaDataChanged = true;

      const resultUpdate = await apiClient.patch(`customer/${edit.id}`, body);
      console.log('customer update', resultUpdate.data);
    } else {
      // Create
      const resultCreate = await apiClient.post(`customer`, body);
      console.log('customer created', resultCreate.data);

      resultCreate.data.metaData = JSON.parse(resultCreate.data.metaData);
      signalEditedCustomer.value = resultCreate.data;
    }

    edit.isDirty = false;

    await getCustomers(); // Reload
    setSelectedCustomer(signalEditedCustomer.value.id);

    toastMessage('Kunden sparad');
  } catch (error) {
    handleApiError(error, 'Kunden kunde inte sparas');
  }
}

export async function saveCustomerCourses(selectedCourses) {
  if (!selectedCourses || selectedCourses.length === 0) return;

  const selectedCustomer = getSelectedCustomer();

  console.log('save customer courses', selectedCourses);

  const promises = selectedCourses.map((course) => {
    console.log(`customer/${selectedCustomer.id}/courses/${course.id}`);
    return apiClient.post(
      `customer/${selectedCustomer.id}/courses/${course.id}`
    );
  });

  try {
    await Promise.all(promises);
    await getCustomers(); // Reload
    toastMessage('Kundens kurser sparade');
  } catch (error) {
    handleApiError(error, 'Kundens kurser kunde inte sparas');
  }
}

export async function deleteCustomer() {
  const edit = signalEditedCustomer.value;

  try {
    // console.log('delete customer', `customer/${editedCustomer.id}`);
    await apiClient.delete(`customer/${edit.id}`);

    signalEditedCustomer.value = null;
    signalSelectedCustomerId.value = null;

    await getCustomers(); // Reload customers

    toastMessage('Kunden raderad');
  } catch (error) {
    toastMessage('Kunden kunde inte raderas');
  }
}

export function getApprovedEmailAsObject() {
  const edit = signalEditedCustomer.value;

  const approvedEmail = edit.approvedEmailSuffixes.map((email, index) => {
    return {
      label: email,
      value: index,
    };
  });

  if (approvedEmail.length === 0) return null;

  return approvedEmail;
}
