import axios from 'axios';
import { toastMessage } from '../state/app-functions';

const getJwtToken = () => {
  let token = JSON.parse(sessionStorage.getItem('token'));
  return token;
};

export const apiClient = axios.create({
  baseURL: '/api',
  timeout: 30000,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjdjNjNhNmMzLTEwODUtNGM4My1iNjgzLWE0YTAxMjA0YzlhZiIsInN1YiI6IkFuZGVycyBOeXN0csO2bSIsImVtYWlsIjoiYW5kbnlzQGxpdmUuc2UiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJTZXJ2aWNlQWRtaW5pc3RyYXRvciIsImV4cCI6MTczMTU3MzA5MywiaXNzIjoiRGlnaXRhbExlYXJuaW5nLnNlIiwiYXVkIjoiRGlnaXRhbExlYXJuaW5nLnNlIn0.BhlI2hvJP97XqcfCGVDVKRdd5f9dkm2xdYy2imN-Aws',
  },
});

export function apiPost(url, body) {
  apiClient
    .post(url, body)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function handleApiError(error, fallbackText) {
  console.log(
    '%capiError',
    'background: orangered; color: white; display: block; padding: 0 5px',
    fallbackText,
    error
  );

  if (!error) {
    console.warn('Error object is null');
    toastMessage(fallbackText);
  }

  if (!error.response) {
    console.warn('error.response is null', error);
    toastMessage(fallbackText);
    return;
  }

  let apiErrorTitle = 'Title not set';
  let apiErrorMessage = 'Message not set';

  [apiErrorTitle, apiErrorMessage] = getResponseError(error.response);

  toastMessage(`${apiErrorTitle} ${apiErrorMessage}`, 'error');
  // throw new Error('Api error');
}

function getResponseError(response) {
  console.log('getResponseError()', response);

  try {
    if (response.status === 404) return ['404: Not found', ''];
    if (response.status === 405) return ['405: Method not allowed', ''];

    if (typeof response.data === 'string') {
      return [response.data, ''];
    }

    const title = response.data.title;
    let message = '';

    if (response.data.errors) {
      message = JSON.stringify(response.data.errors);
    }

    return [title, message];
  } catch (error) {
    console.warn('Try-catch exeption', error);
  }

  return ['Could not get error details', ''];
}
