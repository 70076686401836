import React from 'react';
import { ATTACHMENT_TYPE } from '../../../utils/constants';
import { Image } from 'react-bootstrap';

const SlideMedia = ({ slide }) => {
  return (
    <>
      {slide.attachmentContent && (
        <>
          {slide.attachmentType === ATTACHMENT_TYPE.IMAGE && (
            <Image
              src={`data:image/png;base64,${slide.attachmentContent}`}
              className="img-float-right"
              alt={slide.attachmentName}
              rounded
            />
          )}
        </>
      )}
    </>
  );
};

export default SlideMedia;
