// Used to create select options for "react-select"
export function getAsSelectObject(arr, propLabel, propValue = null) {
  // console.log('getAsSelectObject:', arr);
  if (!arr) return [];

  return arr.map((obj, index) => {
    return {
      label: obj[propLabel],
      value: propValue ? obj[propValue] : index,
    };
  });
}
