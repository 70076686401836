import { apiClient, handleApiError } from '../api';
import { ATTACHMENT_TYPE, FRAGETYP } from '../constants';
import { parallel } from '../utils';
import { increaseLoadingBarProgress } from '../../state/app-functions';
import { changeFavicon } from '../image.util';
import { placeholderSlideImage, previewFavicon } from '../base64.images';

export async function getLessonData(userId, courseId, lessonId, lessonPreview) {
  if (lessonPreview) {
    changeFavicon(previewFavicon);

    // Get lesson (preview) from LocalStorage
    if (lessonPreview.questions) {
      lessonPreview.instancedQuestions = lessonPreview.questions;
    }
    lessonPreview.isPreview = true;

    const questionsWithAttachments = lessonPreview.instancedQuestions.filter(
      (q) => q.attachmentId
    );

    const totalCount = questionsWithAttachments.length;

    // TODO: Move to separate file
    function getAttachmentContent(question) {
      return apiClient
        .get(`question/${question.id}/attachment`)
        .then((res) => {
          question.attachmentContent = res.data.attachmentContent;

          increaseLoadingBarProgress(totalCount);
        })
        .catch((error) => {
          question.attachmentContent = placeholderSlideImage;
          question.attachmentType = ATTACHMENT_TYPE.IMAGE;
          handleApiError(error, 'Det gick inte att hämta frågans attachment');
        });
    }

    await parallel(questionsWithAttachments, getAttachmentContent);

    return lessonPreview;
  }

  // console.log(`lesson/${userId}/${courseId}/${lessonId}`);

  // Get lesson from api
  try {
    const url = `lesson/${userId}/${courseId}/${lessonId}`;
    const result = await apiClient.get(url);

    return result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta lektionen');
  }

  return null;
}

export async function startLesson(userId, lessonId) {
  const date = new Date();
  const url = `Users/${userId}/lessons/${lessonId}/timespent`;
  const body = {
    started: date.toISOString(),
  };

  apiClient.put(url, body);
}

export async function completeLesson(userId, lessonId) {
  const date = new Date();
  const url = `Users/${userId}/lessons/${lessonId}/timespent`;
  const body = {
    completed: date.toISOString(),
  };

  apiClient.put(url, body);
}

export async function saveAnswer(userId, data, time) {
  console.log('saveAnswer');
  const questionId = data.id;
  const answers = data.answers;

  //if (data.hasAnswered) return;

  const url = `answer/${userId}/${questionId}`;
  const body = {
    answers: answers,
    timeSpentOnQuestion: '15:47:40', // TODO: Fix a working timer
  };

  return await apiClient.put(url, body);
}

export async function deleteAnswer(userId, questionId) {
  console.log('deleteAnswer');

  const url = `answer/${userId}/${questionId}`;
  return await apiClient.delete(url);
}

export function showNextSlide(slide, hasAnswered) {
  // hasAnswered is true when the user clicks "Svara"

  console.log('showNextSlide', slide, hasAnswered);
  if (!slide) return;
  if (!slide.hasAnswered) slide.hasAnswered = false;
  if (typeof hasAnswered !== 'undefined') slide.hasAnswered = hasAnswered;

  const showNext =
    slide.type === FRAGETYP.INFORMATION || slide.hasAnswered === true;

  // console.log('showNext', showNext);

  const nextElement = document.getElementsByClassName(
    'carousel-control-next'
  )[0];

  if (!nextElement) return;

  nextElement.style.display = showNext ? 'flex' : 'none';
}
