import React, { useState } from 'react';

const Login = () => {
 

  return (
    //<Col className="align-self-center">
    //  <Row className="justify-content-center">
    //    <Col md="8" lg="6" sm="8" className="mb-2">
    //      <Header />
    //    </Col>
    //  </Row>

    //  <Row className="justify-content-center">
    //    <Col md="8" lg="6" sm="8">
    //      <div className="shadow-container login-container">
    //        <div className="title">Inloggning</div>

    //        {/* Email */}
    //        <FloatingLabel controlId="email" label="E-Post" className="mb-3">
    //          <Form.Control
    //            name="email"
    //            type="email"
    //            placeholder="name@example.com"
    //            onChange={(e) => handleChange(e)}
    //            autoFocus={true}
    //          />
    //        </FloatingLabel>

    //        {/* Password */}
    //        <FloatingLabel
    //          controlId="password"
    //          label="Lösenord"
    //          className="mb-3"
    //        >
    //          <Form.Control
    //            name="password"
    //            type="password"
    //            placeholder="..."
    //            onChange={(e) => handleChange(e)}
    //          />
    //        </FloatingLabel>

    //        {/* Submit */}
    //        <Button
    //          variant="secondary"
    //          size="lg"
    //          className="w-100"
    //          onClick={handleLogin}
    //        >
    //          Logga in
    //        </Button>
    //      </div>

    //      <ToastMessage message={toastMessage} callback={toastCallback} />
    //    </Col>
    //  </Row>
    //</Col>
    <>Login...</>
  );
};

export default Login;
