import { useQueries } from 'react-query';
import { getExternalResource } from '../../../Customers/utils.metadata';

export const useGetFonts = (ids) => {
  return useQueries(
    ids.map((id) => ({
      queryKey: ['admin-customer-font', id],
      queryFn: () => getExternalResource(id),
    }))
  );
};
