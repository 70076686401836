import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Layout from '../Layout/Layout';

const LayoutBase = () => {
  return (
    <Container>
      <Row className="vh-100">
        <Layout>
          <Outlet />
        </Layout>
      </Row>
    </Container>
  );
};

export default LayoutBase;
