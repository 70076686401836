import React from 'react';
import { InputGroup } from 'react-bootstrap';
import './style.scss';

function ListItem({
  name,
  active,
  isSelectable = true,
  onClick,
  onDoubleClick,
  children,
}) {
  return (
    <InputGroup
      className={`component-list-item ${active ? 'active' : ''} ${
        isSelectable ? 'selectable' : ''
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <InputGroup.Text title={name}>{name}</InputGroup.Text>
      {children}
    </InputGroup>
  );
}

export default ListItem;
