import React, { useState } from 'react';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { Button, FloatingLabel, Form, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import { courseTemplate } from '../../../utils/admin/course.utils';
import { deleteCourse, saveCourse, getCourseScorm } from '../Courses/utils';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';

import './style.scss';
import { signalEditedCourse } from '../../../state/admin-courses';

const CourseModal = ({ onModalClose }) => {
  const editedObject = signalEditedCourse.value;

  const [renderCount, setRenderCount] = useState(0);

  const showModal = useSignal(true);
  const objectName = useSignal(editedObject.name);
  const objectActive = useSignal(editedObject.isActive);
  const objectDescription = useSignal(editedObject.description);

  useSignalEffect(() => {
    setRenderCount((prev) => prev + 1);
    editedObject.name = objectName.value;
    editedObject.isActive = objectActive.value;
    editedObject.description = objectDescription.value;
  });

  const closeModal = () => {
    showModal.value = false;
    onModalClose();
  };

  const handleCloseModal = () => {
    if (renderCount > 1) {
      confirmModal(closeModal);
      return;
    }

    closeModal();
  };

  // Save
  const handleSave = () => {
    saveCourse();
  };

  // Delete
  const handleDelete = () => {
    confirmModal(() => {
      deleteCourse();
    }, confirmModalOptionsDelete);
  };

  const handleGetScorm = () => {
    getCourseScorm();
  };

  return (
    <div id="component-course-container">
      {/* Offcanvas */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="bottom"
        className="modal-course-height"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              {/* Name */}
              <EditableContent
                className="me-4"
                value={objectName.value}
                onChange={(value) => (objectName.value = value)}
              />

              {/* Aktiv */}
              <Form.Check
                type="switch"
                id="aktiv-switch"
                className={objectActive.value ? 'text-success' : 'text-muted'}
                label={objectActive.value ? 'Aktiv' : 'Inaktiv'}
                checked={objectActive.value}
                onChange={(e) => {
                  objectActive.value = e.target.checked;
                }}
              />

              {/* Save Button */}
              <Button variant="primary" className="ms-4" onClick={handleSave}>
                Spara
              </Button>

              {/* Delete Button */}
              {editedObject.id !== courseTemplate.id && (
                <Button
                  variant="danger"
                  className="ms-4"
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Description */}
          <FloatingLabel label="Beskrivning" className="mt-3">
            <Form.Control
              type="text"
              value={objectDescription.value || ''}
              onChange={(e) => {
                objectDescription.value = e.target.value;
              }}
            />
          </FloatingLabel>

          {/* Scorm */}
          {editedObject.id !== courseTemplate.id && (
            <Button variant="success" className="mt-4" onClick={handleGetScorm}>
              Hämta SCORM-fil
            </Button>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CourseModal;
