import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { parallel } from '../../utils/utils';
import { apiClient } from '../../utils/api';
import { useQuery } from 'react-query';
import { Spinner } from 'react-bootstrap';
import {
  getInstancedCourse,
  loadExternalResources,
} from '../../utils/home/customer';

function RedirectCustomer() {
  let { guidCustomer } = useParams();
  const navigate = useNavigate();

  const getCustomerData = async (guidCustomer) => {
    const { data } = await apiClient.get(
      `customer/${guidCustomer}/?binaries=true`
    );
    return data;
  };

  const { data, error, isLoading } = useQuery(['customer', guidCustomer], () =>
    getCustomerData(guidCustomer)
  );

  useEffect(() => {
    const loadAndApplyResources = async (customer) => {
      if (!customer) return;
      if (!data.availibleCourses || data.availibleCourses.length === 0) return;

      await loadExternalResources(customer);

      const availableCourses = await parallel(
        customer.availibleCourses,
        getInstancedCourse
      );

      console.log(`All courses loaded`, availableCourses);

      navigate(`/lessons`, {
        replace: true,
        state: { customerId: customer.id, courses: availableCourses },
      });
    };

    loadAndApplyResources(data);
  }, [data, navigate]);

  return (
    <>
      {isLoading && <Spinner animation="border" />}

      {error && <div>Ett fel uppstod: {error.message}</div>}

      {data &&
        (!data.availibleCourses || data.availibleCourses.length === 0) && (
          <div>Det finns inga kurser för denna kund</div>
        )}
    </>
  );
}

export default RedirectCustomer;
