import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

import './style.scss';

const FilterInput = ({ onFilterChange, className }) => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
    onFilterChange(newFilter); // Call the callback function with the new filter value
  };

  return (
    <div className={`filter-input-component ${className}`}>
      <Form.Control
        className="filter-input rounded-pill"
        type="search"
        value={filter}
        // placeholder="Filtrera"
        onChange={handleFilterChange}
      />
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="filter-input-search-icon"
      />
    </div>
  );
};

export default FilterInput;
