import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { signalLessonData } from '../../../state/app';
import { useLocation } from 'react-router-dom';
import logoLightBriab from '../../../assets/briab/logo/briab-logo-white.svg';
import logoBlackBriab from '../../../assets/briab/logo/briab-logo-black.svg';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [darkMode, setDarkMode] = useDarkMode();
  const location = useLocation();
  const [logoLight, setLogoLight] = useState(logoLightBriab);
  const [logoDark, setLogoDark] = useState(logoBlackBriab);

  useEffect(() => {
    const logoLightSession = sessionStorage.getItem('logoLight');
    const logoDarkSession = sessionStorage.getItem('logoDark');

    if (logoLightSession) {
      setLogoLight(`data:image/png;base64,${logoLightSession}`);
    }
    if (logoDarkSession) {
      setLogoDark(`data:image/png;base64,${logoDarkSession}`);
    }
  }, [location]);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="component-home-header">
      {/* Dark Mode */}
      <FontAwesomeIcon
        className="dark-mode-icon"
        icon={darkMode ? faMoon : faSun}
        size="lg"
        onClick={() => handleDarkMode()}
      />

      {/* Logo */}
      {!signalLessonData.value.isPreview && (
        <img
          src={darkMode ? logoDark : logoLight}
          className="logo"
          alt="Logotyp"
        />
      )}
    </div>
  );
};

export default Header;
