import { apiClient, handleApiError } from '../../../utils/api';
import { toastMessage } from '../../../state/app-functions';
import { ATTACHMENT_TYPE } from '../../../utils/constants';
import { downloadBase64 } from '../../../utils/files.utils';
import {
  courseTemplate,
  questionTemplate,
  lessonTemplate,
} from '../../../utils/admin/course.utils';
import {
  signalCourses,
  signalEditedCourse,
  signalEditedLesson,
  signalEditedQuestion,
  signalLessons,
  signalQuestions,
  signalSelectedCourseId,
  signalSelectedLessonId,
  signalSelectedQuestionId,
} from '../../../state/admin-courses';

export async function getCourses() {
  try {
    const result = await apiClient.get(`courses`);
    signalCourses.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta kurser');
  }
}

export async function getLessons() {
  try {
    const result = await apiClient.get(`lessons`);
    signalLessons.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta lektioner');
  }
}

export async function getQuestions() {
  try {
    const result = await apiClient.get(`questions`);
    //console.log('getQuestions', result.data);
    signalQuestions.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta frågor');
  }
}

export function getCourseById(courseId) {
  return signalCourses.value.find((course) => course.id === courseId);
}

export function getLessonById(lessonId) {
  return signalLessons.value.find((lesson) => lesson.id === lessonId);
}

export function getQuestionById(questionId) {
  return signalQuestions.value.find((question) => question.id === questionId);
}

export function getSelectedCourse() {
  return signalCourses.value.find(
    (course) => course.id === signalSelectedCourseId.value
  );
}

export function getSelectedLesson() {
  const lesson = signalLessons.value.find(
    (lesson) => lesson.id === signalSelectedLessonId.value
  );

  lesson.questions = lesson.questions || [];

  return lesson;
}

export function getSelectedQuestion() {
  return signalQuestions.value.find(
    (question) => question.id === signalSelectedQuestionId.value
  );
}

export function getCourseLessons() {
  const selectedCourse = getSelectedCourse();

  return signalLessons.value.filter((lesson) =>
    selectedCourse.lessons?.includes(lesson.id)
  );
}

export function getLessonQuestions() {
  const selectedLesson = getSelectedLesson();

  let result = [];

  selectedLesson.questions?.forEach((questionId) => {
    const question = getQuestionById(questionId);
    result.push(question);
  });

  return result;
}

export async function questionSelected(question) {
  console.log('selected question', question.id);

  const questionCopy = { ...question };

  // Make sure we don't have any null values
  questionCopy.possibleAnswers = questionCopy.possibleAnswers || [];
  questionCopy.correctAnswer = questionCopy.correctAnswer || [];

  // Get attachment
  if (questionCopy.attachmentId) {
    const attachment = await apiClient.get(
      `question/${questionCopy.id}/attachment`
    );

    questionCopy.attachmentContent = attachment.data.attachmentContent;
    questionCopy.attachmentName = attachment.data.attachmentName;
    questionCopy.attachmentType =
      attachment.data.attachmentType || ATTACHMENT_TYPE.IMAGE;
  }

  signalSelectedQuestionId.value = questionCopy.id;
  signalEditedQuestion.value = questionCopy;
}

export function setPossibleAndCorrectAnswers(answers) {
  const edit = signalEditedQuestion.value;

  edit.possibleAnswers = answers.map((answer) => answer.text);
  edit.correctAnswer = answers
    .filter((answer) => answer.correct)
    .map((answer) => answer.text);
}

export async function saveCourse() {
  const edit = signalEditedCourse.value;

  const courseBody = {
    ...courseTemplate,
    name: edit.name,
    isActive: edit.isActive,
    description: edit.description || '',
    lessons: edit.lessons || [],
  };

  try {
    // Update
    if (edit.id !== courseTemplate.id) {
      delete courseBody.id;

      const resultUpdate = await apiClient.put(`course/${edit.id}`, courseBody);

      console.log('course updated', resultUpdate);
    } else {
      // Create
      const resultCreate = await apiClient.post(`course`, courseBody);
      console.log('course created', resultCreate.data);
    }

    await getCourses(); // Reload

    edit.value = null;

    toastMessage('Kursen sparad');
  } catch (error) {
    handleApiError(error, 'Kursen kunde inte sparas');
  }
}

export async function saveLesson() {
  const edit = signalEditedLesson.value;

  const lessonBody = {
    ...lessonTemplate,
    name: edit.name,
    isActive: edit.isActive,
    description: edit.description || '',
    questions: edit.questions || [],
  };

  try {
    // Update
    if (edit.id !== lessonTemplate.id) {
      delete lessonBody.id;
      lessonBody.nameChanged = true;
      lessonBody.isActiveChanged = true;
      lessonBody.descriptionChanged = true;
      lessonBody.questionsChanged = true;

      const resultUpdate = await apiClient.patch(
        `lesson/${edit.id}`,
        lessonBody
      );

      console.log('lesson updated', resultUpdate);
    } else {
      // Create
      const resultCreate = await apiClient.post(`lesson`, lessonBody);
      console.log('lesson created', resultCreate.data);

      // Update course with the new lesson
      const selectedCourse = getSelectedCourse();

      if (!selectedCourse.lessons) {
        selectedCourse.lessons = [];
      }

      selectedCourse.lessons.push(resultCreate.data.id);

      const courseBody = {
        name: selectedCourse.name,
        isActive: selectedCourse.isActive,
        description: selectedCourse.description || '',
        lessons: selectedCourse.lessons,
      };

      // console.log('course to be updated', courseBody);

      await apiClient.put(`course/${selectedCourse.id}`, courseBody);
    }

    await getLessons(); // Reload

    signalEditedCourse.value = null;

    toastMessage('Lektionen sparad');
  } catch (error) {
    handleApiError(error, 'Lektionen kunde inte sparas');
  }
}

export async function updateLessonQuestions(lesson) {
  const lessonBody = {
    questionsChanged: true,
    questions: lesson.questions || [],
  };

  try {
    const resultUpdate = await apiClient.patch(
      `lesson/${lesson.id}`,
      lessonBody
    );

    console.log('lesson updated', resultUpdate);

    await getLessons(); // Reload

    toastMessage('Frågor uppdaterade');
  } catch (error) {
    handleApiError(error, 'Lektionen kunde inte uppdateras');
  }
}

export async function saveQuestion(question = null) {
  const selectedLesson = getSelectedLesson();
  const edit = question || signalEditedQuestion.value;
  // console.log('selectedLesson', selectedLesson);
  console.log('editedQuestion', edit);

  const questionBody = {
    ...questionTemplate,
    name: edit.name,
    isActive: edit.isActive,
    description: edit.description || '',
    type: edit.type,
    answerMessage: edit.answerMessage || '',
    correctAnswerMessage: edit.correctAnswerMessage || '',
    wrongAnswerMessage: edit.wrongAnswerMessage || '',
    possibleAnswers: edit.possibleAnswers,
    correctAnswer: edit.correctAnswer,
    categories: edit.categories || [],
  };

  try {
    // Update
    if (edit.id !== questionTemplate.id) {
      delete questionBody.id;
      questionBody.nameChanged = true;
      questionBody.descriptionChanged = true;
      questionBody.categoriesChanged = true;
      questionBody.answersChanged = true;
      questionBody.possibleAnswersChanged = true;
      questionBody.correctAnswerChanged = true;
      questionBody.correctAnswerMessageChanged = true;
      questionBody.wrongAnswerMessageChanged = true;
      questionBody.answerMessageChanged = true;
      questionBody.isActiveChanged = true;
      questionBody.metaDataChanged = true;
      questionBody.typeChanged = true;

      if (edit.attachmentId === null) {
        console.log('attachment changed');
        questionBody.attachmentNameChanged = true;
        questionBody.attachmentTypeChanged = true;
        questionBody.attachmentContentChanged = true;

        if (edit.attachmentContent) {
          console.log('attachment content');
          questionBody.attachmentContent = edit.attachmentContent;
          questionBody.attachmentName = edit.attachmentName;
          questionBody.attachmentType = edit.attachmentType;
        }
      }

      // console.log(
      //   'update question',
      //   questionBody,
      //   `question/${editedQuestion.id}`
      // );

      const resultUpdate = await apiClient.patch(
        `question/${edit.id}`,
        questionBody
      );

      console.log('question updated', `question/${edit.id}`, resultUpdate);
    } else {
      // Create

      if (edit.attachmentContent?.length > 0) {
        questionBody.attachmentContent = edit.attachmentContent;
        questionBody.attachmentName = edit.attachmentName;
        questionBody.attachmentType = edit.attachmentType;
      }

      const resultCreate = await apiClient.post(`question`, questionBody);
      console.log('question created', resultCreate.data);

      signalSelectedQuestionId.value = null;
      edit.value = null;

      const lessonBody = {
        questionsChanged: true,
        questions: [...selectedLesson.questions, resultCreate.data.id],
      };

      console.log('body', `lesson/${selectedLesson.id}`, lessonBody);

      const resultLessonUpdate = await apiClient.patch(
        `lesson/${selectedLesson.id}`,
        lessonBody
      );

      console.log('lesson updated', resultLessonUpdate.data);
    }

    await getQuestions(); // Reload
    await getLessons(); // Reload

    toastMessage('Svaret sparat');
  } catch (error) {
    handleApiError(error, 'Svaret kunde inte sparas');
  }
}

export async function deleteCourse() {
  try {
    // Delete course
    await apiClient.delete(`course/${signalEditedCourse.value.id}`);

    signalSelectedCourseId.value = null;
    signalSelectedLessonId.value = null;
    signalEditedCourse.value = null;

    await getCourses(); // Reload

    toastMessage('Kursen borttaget');
  } catch (error) {
    handleApiError(error, 'Kursen kunde inte tas bort');
  }
}

export async function deleteLesson() {
  const edit = signalEditedLesson.value;

  try {
    // Delete lesson
    await apiClient.delete(`lesson/${edit.id}`);

    // Update course with the deleted lesson
    const selectedCourse = getSelectedCourse();

    // Remove lesson from course
    selectedCourse.lessons = selectedCourse.lessons.filter(
      (lesson) => lesson !== edit.id
    );

    const courseBody = {
      name: selectedCourse.name,
      isActive: selectedCourse.isActive,
      description: selectedCourse.description || '',
      lessons: selectedCourse.lessons,
    };

    await apiClient.put(`course/${selectedCourse.id}`, courseBody);

    signalSelectedLessonId.value = null;
    signalEditedLesson.value = null;

    await getLessons(); // Reload

    toastMessage('Lektionen borttaget');
  } catch (error) {
    handleApiError(error, 'Lektionen kunde inte tas bort');
  }
}

export async function deleteQuestion() {
  const selectedLesson = getSelectedLesson();
  const edit = signalEditedQuestion.value;

  // console.log('selectedLesson', selectedLesson);
  // console.log('selectedQuestion', selectedQuestion);

  try {
    // Update lesson without the selected question
    const updatedLessonQuestions = selectedLesson.questions.filter(
      (q) => q !== edit.id
    );

    const lessonBody = {
      questionsChanged: true,
      questions: updatedLessonQuestions,
    };

    console.log('updated lesson', `lesson/${selectedLesson.id}`, lessonBody);

    // Update lesson
    await apiClient.patch(`lesson/${selectedLesson.id}`, lessonBody);

    console.log('delete question', `question/${edit.id}`);

    // Delete question
    await apiClient.delete(`question/${edit.id}`);

    signalSelectedQuestionId.value = null;

    await getLessons(); // Reload
    await getQuestions(); // Reload

    toastMessage('Svaret borttaget');
  } catch (error) {
    handleApiError(error, 'Svaret kunde inte tas bort');
  }
}

export function getAllCategoriesAsObject() {
  const uniqueCategories = new Set();

  signalQuestions.value.forEach((question) => {
    question.categories.forEach((category) => {
      uniqueCategories.add(category);
    });
  });

  const arr = [...uniqueCategories];

  if (arr.length === 0) return null;

  return arr.map((category, index) => {
    return {
      label: category,
      value: category,
    };
  });
}

export function getSelectedCategoriesAsObject() {
  const edit = signalEditedQuestion.value;

  const selectedCategories = edit.categories.map((category, index) => {
    return {
      label: category,
      value: category,
    };
  });

  if (selectedCategories.length === 0) return null;

  return selectedCategories;
}

export async function previewLesson(navigate, lessonId, courseId = null) {
  let id = courseId || signalSelectedCourseId.value;

  const result = await apiClient.get(`courses/${id}/preview`);

  const lesson = result.data.lessons.find((lesson) => lesson.id === lessonId);
  const str = JSON.stringify(lesson);

  //console.log('preview lesson', str);

  localStorage.setItem('lessonPreview', str);

  navigate('/', { replace: false });
}

export async function previewInstancedLesson(navigate, lessonId, courseId) {
  const result = await apiClient.get(`instancedcourses/${courseId}`);

  const lesson = result.data.lessons.find((lesson) => lesson.id === lessonId);
  const str = JSON.stringify(lesson);

  // console.log('preview lesson', lesson);

  localStorage.setItem('lessonPreview', str);

  navigate('/', { replace: false });
}

export async function getCourseScorm() {
  const result = await apiClient.get(
    `courses/${signalEditedCourse.value.id}/scorm`
  );

  console.log('Downloading SCORM file');

  const base64 = result.data.attachmentContent;

  downloadBase64(
    base64,
    `scorm-${signalEditedCourse.value.name}.zip`,
    'application/zip'
  );
}
