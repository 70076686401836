import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Editor from '@monaco-editor/react';
import { useNavigate } from 'react-router-dom';
import { encode, decode } from 'js-base64';
import FontList from './FontList/FontList';
import ImageUpload from '../../../Shared/ImageUpload/ImageUpload';
import { signalDarkMode } from '../../../../state/app';
import { customerTemplate } from '../../../../utils/admin/customer.utils';
import { getApprovedEmailAsObject } from '../../Customers/utils';
import { getExternalResource } from '../../Customers/utils.metadata';
import { STYLE_EDITOR_CONFIG } from './editor-config';
import { signalEditedCustomer } from '../../../../state/admin-customers';
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  getBase64FromDataUrl,
  getFileUploadObject,
} from '../../../../utils/files.utils';
import './style.scss';
import CourseList from './CourseList/CourseList';
import TabHeader from '../../../Shared/TabHeader/TabHeader';

const Customer = () => {
  const editedObject = signalEditedCustomer.value;

  const navigate = useNavigate();
  const [orgnummer, setOrgnummer] = useState(editedObject.organizationNumber);
  const [value, setValue] = useState(getApprovedEmailAsObject());
  const [editorContent, setEditorContent] = useState('/* css content */');
  const [logoLightBase64, setLogoLightBase64] = useState(null);
  const [logoDarkBase64, setLogoDarkBase64] = useState(null);
  const [faviconBase64, setFaviconBase64] = useState(null);

  const handleOrgnummerChange = (e) => {
    const orgnummer = e.target.value;
    editedObject.organizationNumber = orgnummer;
    setOrgnummer(orgnummer);
    editedObject.isDirty = true;
  };

  const handleApprovedEmailChange = (newValue) => {
    setValue(newValue);
    editedObject.approvedEmailSuffixes = newValue.map((obj) => obj.label);
    editedObject.isDirty = true;
  };

  const handleEditorChange = (value, event) => {
    editedObject.metaData.style.content = value ? encode(value) : null;
    // console.log('metadata:', editedObject.metaData);
    editedObject.isDirty = true;
  };

  const handleLogoChangeLight = (imageList) => {
    const property = editedObject.metaData.logoLight;
    handleLogoChange(imageList, property);
  };

  const handleLogoChangeDark = (imageList) => {
    const property = editedObject.metaData.logoDark;
    handleLogoChange(imageList, property);
  };

  const handleFaviconChange = (imageList) => {
    const property = editedObject.metaData.favicon;
    handleLogoChange(imageList, property);
  };

  const handleLogoChange = (imageList, property) => {
    const image = imageList[0];

    property.content = null;
    property.name = null;

    editedObject.isDirty = true;

    // Add image
    if (image) {
      property.content = getBase64FromDataUrl(image.data_url);
      property.name = image.file.name;
      return;
    }
  };

  const handleFontChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileObject = await getFileUploadObject(file);
      // console.log('fileObject', fileObject);

      editedObject.metaData.font.content = fileObject.base64;
      editedObject.metaData.font.name = file.name;

      // console.log('metadata:', editedObject.metaData.font.content.length);

      editedObject.isDirty = true;
    }
  };

  const handleShowAvailableCources = () => {
    navigate(`/${editedObject.id}`);
  };

  const handleTabSelect = async (selectedKey) => {
    let property;

    switch (selectedKey) {
      // Logo
      case 'logo':
        // Light Mode
        property = editedObject.metaData.logoLight;
        if (property.externalResourceId && !property.isLoaded) {
          const result = await getExternalResource(property.externalResourceId);
          console.log('logo light result', result);
          setLogoLightBase64(result.content);
          property.isLoaded = true;
        }

        // Dark Mode
        property = editedObject.metaData.logoDark;
        if (property.externalResourceId && !property.isLoaded) {
          const result = await getExternalResource(property.externalResourceId);
          console.log('logo dark result', result);
          setLogoDarkBase64(result.content);
          property.isLoaded = true;
        }

        // Favicon
        property = editedObject.metaData.favicon;
        if (property.externalResourceId && !property.isLoaded) {
          const result = await getExternalResource(property.externalResourceId);
          console.log('favicon result', result);
          setFaviconBase64(result.content);
          property.isLoaded = true;
        }
        break;
      // Style
      case 'style':
        property = editedObject.metaData.style;
        if (property.externalResourceId && !property.isLoaded) {
          const result = await getExternalResource(property.externalResourceId);
          setEditorContent(decode(result.content));
          property.isLoaded = true;
        }
        break;
      // Font
      case 'font':
        property = editedObject.metaData.font;
        // Font is handled by its own component
        break;
      default:
        console.log('default');
    }
  };

  return (
    <Tabs variant="tabs" className="mb-3" onSelect={handleTabSelect}>
      <Tab eventKey="info" title="Information">
        {/* Organisationsnummer */}
        <FloatingLabel label="Organisationsnummer" className="mb-3">
          <Form.Control
            type="text"
            value={orgnummer || ''}
            onChange={handleOrgnummerChange}
          />
        </FloatingLabel>

        <CreatableSelect
          isMulti
          isClearable
          value={value}
          onChange={handleApprovedEmailChange}
          className="react-select"
          placeholder="Godkända Epost-domäner"
        />

        {/* Show available courses */}
        {editedObject.id !== customerTemplate.id && (
          <Button
            variant="success"
            className="mt-4"
            onClick={handleShowAvailableCources}
          >
            Visa tillgängliga kurser
          </Button>
        )}
      </Tab>

      {/* Style */}
      {editedObject.id !== customerTemplate.id && (
        <Tab eventKey="style" title="Stil">
          <Editor
            height="45vh"
            defaultLanguage="css"
            theme={signalDarkMode.value ? 'vs-dark' : 'light'}
            defaultValue={editorContent}
            value={editorContent}
            options={STYLE_EDITOR_CONFIG}
            onChange={handleEditorChange}
          />
        </Tab>
      )}

      {/* Font */}
      {editedObject.id !== customerTemplate.id && (
        <Tab eventKey="font" title="Font">
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Control
              type="file"
              size="lg"
              accept=".woff, .woff2, .ttf"
              onChange={handleFontChange}
            />
          </Form.Group>

          {/* Font list */}
          <FontList />
        </Tab>
      )}

      {/* Logo */}
      {editedObject.id !== customerTemplate.id && (
        <Tab eventKey="logo" title="Logo">
          <Row>
            <Col>
              <div>Light mode</div>
              <ImageUpload
                className="light"
                imageBase64={logoLightBase64}
                imageName="LogoLight.png"
                imageMaxPixels={500}
                confirmOnRemove={true}
                onChange={handleLogoChangeLight}
              />
            </Col>
            <Col>
              <div>Dark mode</div>
              <ImageUpload
                className="dark"
                imageBase64={logoDarkBase64}
                imageName="LogoDark.png"
                imageMaxPixels={500}
                confirmOnRemove={true}
                onChange={handleLogoChangeDark}
              />
            </Col>
            <Col>
              <div>Favicon</div>
              <ImageUpload
                imageBase64={faviconBase64}
                imageName="favicon.png"
                imageMaxPixels={100}
                confirmOnRemove={true}
                onChange={handleFaviconChange}
              />
            </Col>
          </Row>
        </Tab>
      )}

      {/* Courses */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          eventKey="courses"
          title={
            <TabHeader hasContent={editedObject.availibleCourses?.length > 0}>
              Kurser
            </TabHeader>
          }
        >
          <Row>
            <Col>
              <CourseList />
            </Col>
          </Row>
        </Tab>
      )}
    </Tabs>
  );
};

export default Customer;
