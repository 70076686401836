import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const ToastHot = ({ t, text }) => {
  return (
    <div className="react-hot-toast">
      <div className="me-4">{text}</div>

      <div className="divider"></div>

      <FontAwesomeIcon
        icon={faTimes}
        className="close-toast"
        onClick={() => toast.dismiss(t.id)}
      />
    </div>
  );
};

export default ToastHot;
