import { Col, Row } from 'react-bootstrap';
import './style.scss';

const AdminHome = () => {
  return (
    <Row>
      <Col>
        <h1>Admin Home</h1>
      </Col>
    </Row>
  );
};

export default AdminHome;
