import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Row, Col } from 'react-bootstrap';
import ErrorBoundaryFallback from '../../components/Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';
import Header from '../../components/Shared/Header/Header';

const Layout = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Col>
        {/* Header */}
        <Row className="justify-content-center">
          <Col>
            <Header />
          </Col>
        </Row>

        {/* Page Content */}
        <Row className="justify-content-center">
          <Col className="mb-2">
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              {children}
            </ErrorBoundary>
          </Col>
        </Row>
      </Col>
    </ErrorBoundary>
  );
};

export default Layout;
