import toast from 'react-hot-toast';
import ToastHot from '../components/Shared/ToastHot/ToastHot';
import {
  signalConfirmCallback,
  signalConfirmOptions,
  signalConfirmShow,
  signalDarkMode,
  signalLoadingBar,
} from './app';

// #region Toast
export function toastMessage(text, toastType = 'success') {
  if (text.length === 0) return;

  let duration = text.length * 150;

  const defaultConfig = {
    duration: duration,
    style: {
      background: signalDarkMode.value ? '#333' : '#000',
      borderRadius: '6px',
      border: `1px solid red`,
      fontSize: '18px',
      color: '#fff',
      padding: '10px',
      maxWidth: 550,
    },
  };

  switch (toastType) {
    case 'success':
      defaultConfig.style.borderColor = signalDarkMode.value ? '#888' : '#000';
      break;
    case 'error':
      defaultConfig.style.borderColor = 'rgb(215, 82, 82)';
      break;
    case 'loading':
      defaultConfig.style.borderColor = signalDarkMode.value ? '#888' : '#000';
      break;
    default:
      break;
  }

  toast[toastType]((t) => <ToastHot t={t} text={text} />, defaultConfig);
}
// #endregion

// #region LoadingBar
export function increaseLoadingBarProgress(total) {
  signalLoadingBar.value = Math.min(100, signalLoadingBar.value + 100 / total);
}
// #endregion

// #region Confirm Modal
export const confirmModalOptionsDefault = {
  title: 'Lämna sidan?',
  body: 'Är du säker på att du vill lämna den aktuella sidan? Ändringarna du har gjort kommer inte att sparas.',
  cancelText: 'Avbryt',
  confirmText: 'Ok',
  confirmVariant: 'primary',
};

export const confirmModalOptionsDelete = {
  title: 'Ta bort?',
  body: 'Är du säker på att du vill ta bort?',
  confirmText: 'Ta bort',
  confirmVariant: 'danger',
};

export function confirmModal(callback, options) {
  if (options) {
    options = { ...confirmModalOptionsDefault, ...options };
  } else {
    options = confirmModalOptionsDefault;
  }

  signalConfirmCallback.value = callback;
  signalConfirmOptions.value = options;
  signalConfirmShow.value = true;
}

export function confirmModalClose() {
  signalConfirmShow.value = false;
}
// #endregion
