import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Fraga from '../../components/Home/Fraga/Fraga';
import DeleteAnswer from '../../components/Shared/DeleteAnswer/DeleteAnswer';
import HtmlDiv from '../../components/Shared/HtmlDiv/HtmlDiv';
import SlideMedia from '../../components/Home/SlideMedia/SlideMedia';

import './style.scss';

const Slide = ({ slide }) => {
  return (
    <>
      <Row>
        <Col>
          <h1>{slide.name}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          {slide.attachmentContent && (
            <>
              <div className="d-none d-md-block">
                <SlideMedia slide={slide} />
              </div>

              <div className="d-block d-md-none">
                <SlideMedia slide={slide} />
              </div>
            </>
          )}

          <HtmlDiv>{slide.description}</HtmlDiv>

          <Fraga slide={slide} />
        </Col>
      </Row>

      {/*<DeleteAnswer slide={slide} />*/}
    </>
  );
};

export default Slide;
