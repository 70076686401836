import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomerModal from '../CustomerModal/CustomerModal';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import AddButton from '../../Shared/AddButton/AddButton';
import CourseUsersModal from '../CourseUsersModal/CourseUsersModal';
import { getCustomerById, setSelectedCustomer } from './utils';
import {
  signalCustomers,
  signalEditedCustomer,
  signalSelectedCustomerId,
} from '../../../state/admin-customers';
import './style.scss';
import ListItem from '../ListItem/ListItem';

const Customers = () => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showCourseUsersModal, setShowCourseUsersModal] = useState(false);

  const handleModalClose = () => {
    setShowCustomerModal(false);
    signalEditedCustomer.value = null;
  };

  const handleEditCustomer = async (e, customerId) => {
    e.stopPropagation();
    signalEditedCustomer.value = getCustomerById(customerId);
    setSelectedCustomer(customerId);
    setShowCustomerModal(true);
  };

  const handleNewCustomer = () => {
    signalEditedCustomer.value = { ...customerTemplate };
    setShowCustomerModal(true);
  };

  return (
    <div>
      {signalCustomers.value && (
        <Row>
          <Col>
            {/* Customers */}
            {signalCustomers.value.map(function (customer, index) {
              return (
                <ListItem
                  name={customer.name}
                  key={index}
                  active={signalSelectedCustomerId.value === customer.id}
                  onClick={async (e) => {
                    console.log('Selected Customer', customer.id);
                    setSelectedCustomer(customer.id);
                    await handleEditCustomer(e, customer.id);
                  }}
                />
              );
            })}

            {/* Add Customer */}
            <AddButton onClick={handleNewCustomer} />
          </Col>
          <Col>{/* Fake */}</Col>
        </Row>
      )}

      {/* Customer Modal */}
      {showCustomerModal && signalEditedCustomer.value && (
        <CustomerModal onModalClose={handleModalClose} />
      )}

      {/* Course Users Modal */}
      {showCourseUsersModal && (
        <CourseUsersModal onModalClose={() => setShowCourseUsersModal(false)} />
      )}
    </div>
  );
};

export default Customers;
