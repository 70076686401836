import React, { useCallback, useEffect, useState } from 'react';
import ReactImageUploading from 'react-images-uploading';
import { Button } from 'react-bootstrap';
import { downloadFile } from '../../../utils/files.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { resizeImage, svgToPng } from '../../../utils/image.util';
import { base64ToBlob, getDataUrlMimeType } from '../../../utils/files.utils';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';

import './style.scss';

const ImageUpload = ({
  className,
  imageBase64,
  imageName,
  imageMaxPixels,
  confirmOnRemove,
  onChange,
}) => {
  const getImageObject = useCallback(() => {
    if (!imageBase64) return [];

    const imageObject = {
      data_url: `data:image/png;base64,${imageBase64}`,
      file: {
        name: imageName,
      },
    };
    // console.log('imageObject', imageObject);

    //console.log('imageInfo', attachmentInfo);

    return [imageObject];
  }, [imageBase64, imageName]);

  useEffect(() => {
    if (imageBase64) setImages(getImageObject());
  }, [getImageObject, imageBase64]);

  const [images, setImages] = useState(getImageObject());
  const maxNumber = 1;

  const handleImageChange = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let dataUrl = imageList[0].data_url;

      if (getDataUrlMimeType(dataUrl).includes('svg+xml')) {
        dataUrl = await svgToPng(dataUrl);
      }

      const blob = await base64ToBlob(dataUrl, 'image/png');
      const fileName = imageList[0].file.name;

      if (imageMaxPixels) {
        const resizedImage = await resizeImage(fileName, blob, imageMaxPixels);
        // console.log('resizedImage', resizedImage);
        imageList[0].data_url = resizedImage;
      }
    }

    //console.log('imagelist', imageList);

    setImages(imageList);
    onChange(imageList);
  };

  const handleImageDownload = (image) => () => {
    downloadFile(image.data_url, image.file.name);
  };

  const handleImageRemove = (index, callback) => {
    if (confirmOnRemove === true) {
      confirmModal(() => {
        callback(index);
      }, confirmModalOptionsDelete);
      return;
    }

    callback(index);
  };

  return (
    <div id="component-image-upload" className={className}>
      <ReactImageUploading
        multiple
        value={images}
        onChange={handleImageChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg', 'jpeg', 'png', 'svg']}
      >
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div
            {...dragProps}
            className={`media-drop-zone ${isDragging ? 'dragging' : ''}`}
          >
            {imageList.length === 0 && (
              <Button
                variant="primary"
                className="btn-add-image"
                onClick={onImageUpload}
              >
                Välj bild
              </Button>
            )}
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div>
              {imageList.map((image, index) => (
                <div key={index}>
                  <div className="image-info">
                    <div className="text-muted ">{image.file.name}</div>
                    {/* Edit */}
                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={() => onImageUpdate(index)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>

                    {/* Download */}
                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={handleImageDownload(image)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>

                    {/* Remove */}
                    <Button
                      variant="danger"
                      className="ms-2"
                      onClick={() => handleImageRemove(index, onImageRemove)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={image.data_url} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ReactImageUploading>
    </div>
  );
};

export default ImageUpload;
