// Get current date, ex 2021-07-01
export const getDate = () => {
  var date = new Date();
  return date.toISOString().substring(0, 10);
};

// Get date and time in 2021-07-01 12:01:15 format
export const getDateTime = (dt) => {
  var date = new Date(dt) || new Date();
  return date.toISOString().substring(0, 19).replace('T', ' ');
};

export const compareArrays = (arr1, arr2) => {
  const arr1Sorted = arr1.slice().sort();
  const arr2Sorted = arr2.slice().sort();

  if (arr1Sorted.length !== arr2Sorted.length) return false;

  const result = arr2Sorted.every(function (value, index) {
    return value === arr1Sorted[index];
  });

  return result;
};

export const flattenArray = (arr) => {
  return arr.reduce((acc, val) => acc.concat(val), []);
};

export const validateOrganisationsnummer = (nr) => {
  /*  The code contained in this  file is copyrighted by www.jojoxx.net
    The file may be used for none commercial applications and distributed
    as long as these lines remain intact.  The file or part of it may not
    be sold  or  included  in any  other commercial  application  without
    agreement from the author. If you have questions or comments, contact
    the author at http://www.jojoxx.net

    © Copyright - www.jojoxx.net - 2004                                */

  // eslint-disable-next-line no-useless-escape
  if (!nr.match(/^(\d{1})(\d{5})\-(\d{4})$/)) {
    return false;
  }
  let group = RegExp.$1;
  let controldigits = RegExp.$3;
  let alldigits = group + RegExp.$2 + controldigits;
  if (alldigits.substring(2, 3) < 2) {
    return false;
  }

  let n = 0;
  let nn = '';

  for (n = 0; n < alldigits.length; n += 1) {
    nn += (((n + 1) % 2) + 1) * alldigits.substring(n, n + 1);
  }

  let checksum = 0;
  for (n = 0; n < nn.length; n += 1) {
    checksum += nn.substring(n, n + 1) * 1;
  }

  let isValid = checksum % 10 === 0 ? true : false;
  return isValid;
};

export const validatePersonnummer = (nr) => {
  // Check valid length & form
  if (!nr) {
    return false;
  }

  if (nr.indexOf('-') === -1) {
    if (nr.length === 10) {
      nr = nr.slice(0, 6) + '-' + nr.slice(6);
    } else {
      nr = nr.slice(0, 8) + '-' + nr.slice(8);
    }
  }

  if (
    // eslint-disable-next-line no-useless-escape
    !nr.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)
  ) {
    return false;
  }

  // Clean input
  nr = nr.replace('-', '');
  if (nr.length === 12) {
    nr = nr.substring(2);
  }

  // Declare variables
  let d = new Date(
      !!RegExp.$1 ? RegExp.$1 : RegExp.$5,
      (!!RegExp.$2 ? RegExp.$2 : RegExp.$6) - 1,
      !!RegExp.$3 ? RegExp.$3 : RegExp.$7
    ),
    sum = 0,
    numdigits = nr.length,
    parity = numdigits % 2,
    i = 0,
    digit = 0;

  // Check valid date
  if (
    Object.prototype.toString.call(d) !== '[object Date]' ||
    isNaN(d.getTime())
  )
    return false;

  // Check luhn algorithm
  for (i = 0; i < numdigits; i = i + 1) {
    digit = parseInt(nr.charAt(i), 10);
    if (i % 2 === parity) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }

  return sum % 10 === 0;
};

export const validateEmail = (email) => {
  var re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  return re.test(email);
};

export const isUrlAbsolute = (url) =>
  url.indexOf('//') === 0
    ? true
    : url.indexOf('://') === -1
    ? false
    : url.indexOf('.') === -1
    ? false
    : url.indexOf('/') === -1
    ? false
    : url.indexOf(':') > url.indexOf('/')
    ? false
    : url.indexOf('://') < url.indexOf('.')
    ? true
    : false;

export const debounce = (fn, time) => {
  /*
  Example
  onChange: debounce((e) => doSomething(e), 500)
  */
  let timeoutId;
  return wrapper;

  function wrapper(...args) {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
};

export const parallel = async (arr, fn, threads = 3) => {
  const result = [];
  while (arr.length) {
    const res = await Promise.all(arr.splice(0, threads).map((x) => fn(x)));
    result.push(res);
  }
  return result.flat();
};
