import React, { useState } from 'react';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { NavLink, Outlet } from 'react-router-dom';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'react-bootstrap';
import { themes } from './sidebar.config';
import { changeFavicon } from '../../../utils/image.util';
import { adminFavicon } from '../../../utils/base64.images';
import { getCourses, getLessons, getQuestions } from '../Courses/utils';
import { getUsers } from '../Users/utils';
import { getCustomers } from '../Customers/utils';
import {
  faBook,
  faBuilding,
  faChevronLeft,
  faChevronRight,
  faMoon,
  faSun,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from '../../Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';

/*
https://github.com/azouaoui-med/react-pro-sidebar/blob/master/storybook/Playground.tsx
https://codesandbox.io/p/sandbox/react-d6h40z?file=%2Fsrc%2FSidebar.js
*/

const AdminSidebar = () => {
  const [darkMode, setDarkMode] = useDarkMode();
  const [collapsed, setCollapsed] = useState(true);

  getCourses();
  getLessons();
  getQuestions();
  getCustomers();
  getUsers();

  changeFavicon(adminFavicon);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="component-admin-sidebar vh-100 d-flex">
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <Sidebar
          collapsed={collapsed}
          toggled={false}
          backgroundColor={themes['dark'].sidebar.backgroundColor}
          borderColor="red"
          rootStyles={{
            color: themes['dark'].sidebar.color,
          }}
        >
          <Menu>
            {collapsed ? (
              <MenuItem
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={() => setCollapsed(!collapsed)}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={() => setCollapsed(!collapsed)}
              >
                <div
                  style={{
                    padding: '9px',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: '1px',
                  }}
                >
                  DL Admin
                </div>
              </MenuItem>
            )}
            <hr />
          </Menu>
          <Menu
            menuItemStyles={{
              button: {
                [`&:hover`]: {
                  backgroundColor: 'royalblue',
                },
                [`&.active`]: {
                  backgroundColor: '#13395e',
                  color: '#b6c8d9',
                  fontWeight: 'bold',
                },
              },
            }}
          >
            <MenuItem
              icon={<FontAwesomeIcon icon={faBuilding} />}
              component={<NavLink to="/admin/customers" />}
            >
              Kunder
            </MenuItem>

            <MenuItem
              icon={<FontAwesomeIcon icon={faBook} />}
              component={<NavLink to="/admin/courses" />}
            >
              Kurser
            </MenuItem>

            <MenuItem
              icon={<FontAwesomeIcon icon={faUser} />}
              component={<NavLink to="/admin/users" />}
            >
              Användare
            </MenuItem>
            {/* 
          <SubMenu label="Test" icon={<FontAwesomeIcon icon={faBaby} />}>
            <MenuItem> Pie charts </MenuItem>
            <MenuItem> Line charts </MenuItem>
          </SubMenu> */}

            <Menu
              menuItemStyles={{
                button: {
                  [`&:hover`]: {
                    backgroundColor: 'royalblue',
                  },
                  [`&.active`]: {
                    backgroundColor: '#13395e',
                    color: '#b6c8d9',
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <hr />
              {/* Dark Mode */}
              <MenuItem
                icon={
                  <FontAwesomeIcon icon={darkMode ? faMoon : faSun} size="lg" />
                }
                onClick={() => handleDarkMode()}
              >
                Theme
              </MenuItem>
            </Menu>
          </Menu>
        </Sidebar>

        <main className="admin-sidebar-main w-100">
          <Container>
            <Outlet />
          </Container>
        </main>
      </ErrorBoundary>
    </div>
  );
};

export default AdminSidebar;
