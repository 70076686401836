import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import './style.scss';
import { getAsSelectObject } from '../../../../../utils/admin/common.utils';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { getCustomerCourses } from '../../../Customers/utils';
import { signalCourses } from '../../../../../state/admin-courses';
import { previewInstancedLesson } from '../../../Courses/utils';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ListItem from '../../../ListItem/ListItem';
import { apiClient, handleApiError } from '../../../../../utils/api';
import {
  signalEditedCustomer,
  signalSelectedCustomerId,
} from '../../../../../state/admin-customers';

const CourseList = () => {
  const editedObject = signalEditedCustomer.value;

  const navigate = useNavigate();

  const [course, setCourse] = useState(null);
  const [instansiatedCourses, setInstansiatedCourses] = useState([]);
  const selectectableCourses = getAsSelectObject(
    signalCourses.value,
    'name',
    'id'
  );

  const selectCourseRef = useRef(null);

  const handleCoursesChange = (course) => {
    setCourse(course);
  };

  useEffect(() => {
    async function fetchData() {
      const courses = await getCustomerCourses();
      setInstansiatedCourses(courses);
    }
    fetchData();
  }, []);

  const handleInstanciateCourse = async () => {
    // console.log('instanciate course', course);

    try {
      const instantiatedCourse = await apiClient.post(
        `customer/${signalSelectedCustomerId.value}/courses/${course.value}`
      );
      console.log('instantiatedCourse', instantiatedCourse.data);

      setInstansiatedCourses([...instansiatedCourses, instantiatedCourse.data]);
      if (!editedObject.availibleCourses) editedObject.availibleCourses = [];
      editedObject.availibleCourses.push(instantiatedCourse.data.id);

      setCourse(null);
      selectCourseRef.current?.clearValue();
    } catch (error) {
      handleApiError(error, 'Kursen kunde inte instansieras');
    }
  };

  const handlePreviewLesson = async (e, course, lesson) => {
    e.stopPropagation();
    await previewInstancedLesson(navigate, lesson.id, course.id);
  };

  return (
    <>
      <Row>
        <div className="d-flex align-items-center">
          <Select
            ref={selectCourseRef}
            isClearable
            isSearchable={true}
            isMulti={false}
            options={selectectableCourses}
            onChange={handleCoursesChange}
            placeholder="Välj kurs"
            tabSelectsValue={false}
            className="react-select w-50"
          />

          <Button
            variant="success"
            disabled={!course}
            className="ms-3"
            onClick={handleInstanciateCourse}
          >
            Instansiera
          </Button>
        </div>
      </Row>

      <Row>
        <Col className="mt-2">
          {instansiatedCourses.map((course, index) => (
            <Accordion key={course.id}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <span>{course.name}</span>
                  <span className="text-muted ps-2">
                    v{course.courseVersion}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {course.lessons.map((lesson, index) => (
                    <div key={index}>
                      {/* <span>{lesson.name}</span>
                    <span className="text-muted ps-2">v{lesson.version}</span> */}

                      <ListItem
                        name={`${lesson.name} (v${lesson.version})`}
                        key={index}
                        isSelectable={false}
                      >
                        <Button
                          variant="outline-secondary"
                          onClick={(e) =>
                            handlePreviewLesson(e, course, lesson)
                          }
                        >
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                      </ListItem>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Col>
        <Col>{/* fake */}</Col>
      </Row>
    </>
  );
};

export default CourseList;
