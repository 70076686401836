import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './style.scss';

function LessonSelect() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  console.log('state', state);

  const handleSelectedLesson = (course, lesson) => () => {
    console.log('Selected lesson', lesson, {
      customerId: state.customerId,
      lessonId: lesson.id,
    });

    // Remove preview data
    localStorage.removeItem('lessonPreview');

    navigate(`/`, {
      replace: false,
      state: { userId: state.userId, courseId: course.id, lessonId: lesson.id },
    });
  };

  return (
    <div className="component-lesson-select">
      {state.courses.map((course, index) => (
        <div className="list" key={index}>
          <h2>{course.name}</h2>
          <div className="text-muted">{course.description}</div>
          <ul>
            {course.lessons.map((lesson, index) => (
              <li
                className="d-flex align-items-center"
                key={index}
                onClick={handleSelectedLesson(course, lesson)}
              >
                <span>{lesson.name}</span>
                <span className="lesson-description">{lesson.description}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default LessonSelect;
