import React, { useEffect, useState } from 'react';

import './style.scss';

export default function PrettyJson({ data }) {
  const [state, setState] = useState(true);

  useEffect(() => {
    // Force the update of complex objects
    const intervalID = setInterval(() => {
      setState(!state);
    }, 300);

    return () => {
      clearInterval(intervalID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="component-prettyjson">
      <pre>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre>
    </div>
  );
}
