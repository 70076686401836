import React, { useState } from 'react';
import {
  Modal,
  Pagination,
  Button,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
import FilterInput from '../../Shared/FilterInput/FilterInput';

import './style.scss';
import { signalEditedCustomerCourse } from '../../../state/admin-customers';
import { signalUsers } from '../../../state/admin-users';

function applyFilter(items, selectedItems, freetextFilter) {
  // Filter on freetext
  let filteredItems = items.filter((item) => {
    return item.name.toLowerCase().includes(freetextFilter.toLowerCase());
  });

  // Filter out already selected users
  filteredItems = filteredItems.filter((item) => {
    return !selectedItems.some((selectedUser) => selectedUser.id === item.id);
  });

  return filteredItems;
}

function CourseUsersModal({ onModalClose }) {
  const edit = signalEditedCustomerCourse.value;
  const [freetextFilter, setFreetextFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState([]); // TODO: Get from state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  let filteredItems = applyFilter(
    signalUsers.value,
    selectedItems,
    freetextFilter
  );

  // Get current users
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  let currentUsers = filteredItems.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSelectUser = (user) => {
    setSelectedItems([...selectedItems, user]);
  };

  const handleDeselectUser = (user) => {
    setSelectedItems(selectedItems.filter((u) => u.id !== user.id));
  };

  return (
    <Modal
      show={true}
      onHide={onModalClose}
      size="lg"
      className="customer-course-modal-component"
    >
      <Modal.Header closeButton>
        <Modal.Title>{edit.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Freetext filter */}
        <FilterInput className="mb-3" onFilterChange={setFreetextFilter} />

        <Row>
          <Col>
            <h5 className="mb-2 ">Användare</h5>

            {/* All users */}
            <ListGroup>
              {currentUsers.map((user, index) => (
                <ListGroup.Item
                  key={index}
                  onClick={() => handleSelectUser(user)}
                >
                  {user.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            {/* Selected Users */}
            <h5 className="mb-2">Valda</h5>

            <ListGroup>
              {selectedItems.map((user, index) => (
                <ListGroup.Item
                  key={index}
                  onClick={() => handleDeselectUser(user)}
                >
                  {user.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>

        {/* Pagination */}
        <Pagination size="sm" className="mt-2">
          {Array.from({
            length: Math.ceil(filteredItems.length / itemsPerPage),
          }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="me-2" onClick={onModalClose}>
          Avbryt
        </Button>
        <Button onClick={onModalClose}>Spara</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CourseUsersModal;
