import React from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastMessage } from '../../../state/app-functions';

import './style.scss';

const CopyToClipboardButton = ({ text, className }) => {
  const handleCopy = () => {
    toastMessage('Kopierat', 'success');
  };

  return (
    <div className="copy-to-clipboard">
      <CopyToClipboard text={text} onCopy={handleCopy} className={className}>
        <FontAwesomeIcon icon={faCopy} />
      </CopyToClipboard>
    </div>
  );
};

export default CopyToClipboardButton;
