import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import CourseModal from '../CourseModal/CourseModal';
import QuestionModal from '../QuestionModal/QuestionModal';
import AddButton from '../../Shared/AddButton/AddButton';
import LessonModal from '../LessonModal/LessonModal';
import {
  courseTemplate,
  lessonTemplate,
  questionTemplate,
} from '../../../utils/admin/course.utils';
import {
  getCourseLessons,
  getLessonQuestions,
  questionSelected,
  previewLesson,
} from './utils';
import {
  signalCourses,
  signalEditedCourse,
  signalEditedLesson,
  signalEditedQuestion,
  signalLessons,
  signalSelectedCourseId,
  signalSelectedLessonId,
  signalSelectedQuestionId,
} from '../../../state/admin-courses';
import './style.scss';
import ListItem from '../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Courses = () => {
  const navigate = useNavigate();

  const handleNewCourse = () => {
    signalEditedCourse.value = { ...courseTemplate, name: 'Ny kurs' };
  };

  const handleNewLesson = () => {
    signalEditedLesson.value = { ...lessonTemplate, name: 'Ny lektion' };
  };

  const handleNewQuestion = () => {
    signalEditedQuestion.value = { ...questionTemplate, name: 'Ny fråga' };
  };

  const handleSelectedQuestion = async (question) => {
    await questionSelected(question);
  };

  const handleCourseModalClose = () => {
    signalEditedCourse.value = null;
  };

  const handleLessonModalClose = () => {
    signalEditedLesson.value = null;
  };

  const handleQuestionModalClose = () => {
    signalSelectedQuestionId.value = null;
    signalEditedQuestion.value = null;
  };

  const handleEditCourse = (e, course) => {
    e.stopPropagation();
    signalEditedCourse.value = { ...course };
  };

  const handleEditLesson = (e, lesson) => {
    e.stopPropagation();
    signalEditedLesson.value = { ...lesson };
  };

  const handlePreviewLesson = async (e, lesson) => {
    e.stopPropagation();
    await previewLesson(navigate, lesson.id);
  };

  return (
    <div className="component-admin-courses">
      <>
        {/* Spinner */}
        {signalLessons.value.length === 0 && (
          <div>
            <Spinner animation="grow" />
          </div>
        )}

        <div className="grid">
          <div className="grid-item">
            {/* Courses */}
            <>
              {signalCourses.value.map(function (course, index) {
                return (
                  <ListItem
                    name={course.name}
                    key={index}
                    active={signalSelectedCourseId.value === course.id}
                    onClick={() => {
                      signalSelectedQuestionId.value = null;
                      signalSelectedLessonId.value = null;
                      signalSelectedCourseId.value = course.id;
                    }}
                    onDoubleClick={(e) => handleEditCourse(e, course)}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={(e) => handleEditCourse(e, course)}
                    >
                      Edit
                    </Button>
                  </ListItem>
                );
              })}

              {/* Add Course */}
              <AddButton
                onClick={handleNewCourse}
                className={signalCourses.value.length === 0 ? '' : 'mt-2'}
              />
            </>
          </div>
          <div className="grid-item">
            {/* Lessons */}
            {signalSelectedCourseId.value && (
              <>
                {getCourseLessons().map(function (lesson, index) {
                  return (
                    <ListItem
                      name={lesson.name}
                      key={index}
                      active={signalSelectedLessonId.value === lesson.id}
                      onClick={() => {
                        //console.log('selectedLesson', lesson);
                        signalSelectedQuestionId.value = null;
                        signalSelectedLessonId.value = lesson.id;
                      }}
                      onDoubleClick={(e) => handleEditLesson(e, lesson)}
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => handlePreviewLesson(e, lesson)}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => handleEditLesson(e, lesson)}
                      >
                        Edit
                      </Button>
                    </ListItem>
                  );
                })}

                {/* Add Lesson */}
                <AddButton onClick={handleNewLesson} className="mt-2" />
              </>
            )}
          </div>
          <div className="grid-item">
            {/* Questions */}
            {signalSelectedLessonId.value && (
              <>
                {getLessonQuestions().map(function (question, index) {
                  return (
                    <ListItem
                      name={question.name}
                      key={index}
                      active={signalSelectedQuestionId.value === question.id}
                      className={question.isActive ? '' : 'text-muted'}
                      onClick={async () => {
                        // console.log('selectedQuestion', question);
                        await handleSelectedQuestion(question);
                      }}
                    ></ListItem>
                  );
                })}

                {/* Add Question */}
                <AddButton onClick={handleNewQuestion} className="mt-2" />
              </>
            )}
          </div>
        </div>

        {/* Course Modal */}
        {signalEditedCourse.value && (
          <CourseModal onModalClose={handleCourseModalClose} />
        )}

        {/* Lesson Modal */}
        {signalEditedLesson.value && (
          <LessonModal onModalClose={handleLessonModalClose} />
        )}

        {/* Question Modal */}
        {signalEditedQuestion.value && (
          <QuestionModal onModalClose={handleQuestionModalClose} />
        )}
      </>
    </div>
  );
};

export default Courses;
