import React, { useState } from 'react';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import Customer from './Customer/Customer';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import { deleteCustomer, saveCustomer } from '../Customers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';
import { signalEditedCustomer } from '../../../state/admin-customers';
import './style.scss';

const CustomerModal = ({ onModalClose }) => {
  const editedObject = signalEditedCustomer.value;

  const [, setShowModal] = useState(true);
  const [customerName, setCustomerName] = useState(editedObject.name);
  const [customerActive, setCustomerActive] = useState(editedObject.isActive);

  const handleCloseModal = () => {
    if (editedObject.isDirty) {
      confirmModal(closeModal);
      return;
    }

    closeModal();
  };

  const closeModal = () => {
    setShowModal(false);
    onModalClose();
  };

  const handleNameChange = (value) => {
    editedObject.name = value;
    editedObject.isDirty = true;
    setCustomerName(value);
  };

  const handleActiveChange = (e) => {
    const isActive = e.target.checked;
    editedObject.isActive = isActive;
    editedObject.isDirty = true;
    setCustomerActive(isActive);
  };

  // Delete
  const handleDelete = () => {
    confirmModal(async () => {
      await deleteCustomer();
      handleCloseModal();
    }, confirmModalOptionsDelete);
  };

  return (
    <div className="component-offcanvas-customer">
      {/* Offcanvas */}
      <Offcanvas
        show={true}
        onHide={handleCloseModal}
        placement="bottom"
        className="modal-customer-height"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              {/* Customer Name */}
              <EditableContent
                className="me-4"
                value={customerName}
                onChange={handleNameChange}
              />

              {/* Aktiv */}
              <Form.Check
                type="switch"
                id="customer-aktiv-switch"
                className={customerActive ? 'text-success' : 'text-muted'}
                label={customerActive ? 'Aktiv' : 'Inaktiv'}
                checked={customerActive}
                onChange={handleActiveChange}
              />

              {/* Save Customer Button */}
              <Button
                variant="primary"
                className="ms-4"
                onClick={() => {
                  saveCustomer();
                }}
              >
                Spara
              </Button>

              {/* Delete Customer Button */}
              {editedObject.id !== customerTemplate.id && (
                <Button
                  variant="danger"
                  className="ms-4"
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Customer />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CustomerModal;
