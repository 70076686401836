import React from 'react';
import { compareArrays } from '../../../utils/utils';
import HtmlDiv from '../../Shared/HtmlDiv/HtmlDiv';
import { FRAGETYP } from '../../../utils/constants';
import { encode } from 'js-base64';

import './style.scss';

const getCorrectWrongAnswer = (slide) => {
  // console.log('A', slide.answers);
  // console.log('AC', slide.correctAnswer);
  // console.log('CM', slide.correctAnswerMessage);
  // console.log('WM', slide.wrongAnswerMessage);

  if (slide.correctAnswer.length === 0) return slide.correctAnswerMessage;

  if (slide.type === FRAGETYP.MULTI) {
    // Multi answers are stored as base64 encoded strings
    const correctAnswerBase64 = slide.correctAnswer.map((answer) =>
      encode(answer)
    );

    if (compareArrays(slide.answers, correctAnswerBase64)) {
      return slide.correctAnswerMessage;
    }
  }

  if (compareArrays(slide.answers, slide.correctAnswer)) {
    return slide.correctAnswerMessage;
  }

  return slide.wrongAnswerMessage;
};

const Answer = ({ slide, hasAnswered }) => {
  return (
    <div className="component-answer">
      {hasAnswered && (
        <>
          {slide.correctAnswer && (
            <HtmlDiv className="answer-response">
              {getCorrectWrongAnswer(slide)}
            </HtmlDiv>
          )}

          {slide.answerMessage && (
            <HtmlDiv className="answer-response">{slide.answerMessage}</HtmlDiv>
          )}
        </>
      )}
    </div>
  );
};

export default Answer;
