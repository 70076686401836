import React, { useState } from 'react';
import { FloatingLabel, Form, Offcanvas } from 'react-bootstrap';
import RippleButton from '../../Shared/RippleButton/RippleButton';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import Select from 'react-select';
import { userTemplate } from '../../../utils/admin/user.utils';
import { getAsSelectObject } from '../../../utils/admin/common.utils';
import { deleteUser, getUserCustomersAsObject, saveUser } from '../Users/utils';
import { signalEditedUser } from '../../../state/admin-users';
import { signalCustomers } from '../../../state/admin-customers';
import './style.scss';

const UserModal = ({ onModalClose }) => {
  const editedObject = signalEditedUser.value;

  const [, setShowModal] = useState(true);
  const [userName, setUserName] = useState(editedObject.name);
  const [epost, setEpost] = useState(editedObject.email);
  const [losenord, setLosenord] = useState(editedObject.password);
  const [userActive, setUserActive] = useState(editedObject.isActive);
  const [value, setValue] = useState(getUserCustomersAsObject());

  const handleCloseModal = () => {
    setShowModal(false);
    onModalClose();
  };

  const handleNameChange = (text) => {
    editedObject.name = text;
    setUserName(text);
  };

  const handleActiveChange = (e) => {
    const isActive = e.target.checked;
    editedObject.isActive = isActive;
    setUserActive(isActive);
  };

  const handleEpostChange = (e) => {
    const epost = e.target.value;
    editedObject.email = epost;
    setEpost(epost);
  };

  const handleLosenordChange = (e) => {
    const loseord = e.target.value;
    editedObject.password = loseord;
    setLosenord(loseord);
  };

  const handleUserCustomersChange = (newValue) => {
    setValue(newValue);
    editedObject.customers = newValue.map((obj) => obj.value);
  };

  return (
    <div className="component-offcanvas-user">
      {/* Offcanvas */}
      <Offcanvas
        show={true}
        onHide={handleCloseModal}
        placement="bottom"
        className="modal-user-height"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              {/* User Name */}
              <EditableContent
                className="me-4"
                value={userName}
                onChange={handleNameChange}
              />

              {/* Aktiv */}
              <Form.Check
                type="switch"
                id="user-aktiv-switch"
                className={userActive ? 'text-success' : 'text-muted'}
                label={userActive ? 'Aktiv' : 'Inaktiv'}
                checked={userActive}
                onChange={handleActiveChange}
              />

              {/* Save User Button */}
              <RippleButton
                className="btn btn-sm btn-secondary ms-4"
                onClick={() => {
                  saveUser();
                }}
              >
                Spara
              </RippleButton>

              {/* Delete User Button */}
              {editedObject.id !== userTemplate.id && (
                <RippleButton
                  className="btn btn-sm btn-danger ms-4"
                  onClick={() => {
                    deleteUser();
                  }}
                >
                  Ta bort
                </RippleButton>
              )}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* E-post */}
          <FloatingLabel label="E-post" className="mb-3">
            <Form.Control
              type="text"
              value={epost}
              onChange={handleEpostChange}
            />
          </FloatingLabel>

          {/* Lösenord */}
          <FloatingLabel label="Lösenord" className="mb-3">
            <Form.Control
              type="text"
              value={losenord}
              onChange={handleLosenordChange}
            />
          </FloatingLabel>

          {/* Kopplade kunder */}
          <Select
            isMulti
            isClearable
            value={value}
            closeMenuOnSelect={false}
            options={getAsSelectObject(signalCustomers.value, 'name', 'id')}
            onChange={handleUserCustomersChange}
            className="react-select"
            placeholder="Kunder"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default UserModal;
