import React from 'react';
import Layout from '../../containers/Layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const PageNotFound = () => {
  return (
    <Layout>
      <div id="page-notfound">
        <div className="shadow-container">
          <div className="title">404</div>

          <div className="d-flex align-items-center mt-3">
            <div className="me-3">
              <FontAwesomeIcon icon={faBug} size="3x" />
            </div>
            <div className="error-text">Sidan kunde inte hittas</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
