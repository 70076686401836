import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './hooks/useAuth';
import { signalLoadingBar } from './state/app';
import { Toaster } from 'react-hot-toast';
import LayoutBase from './containers/LayoutBase/LayoutBase';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Lesson from './pages/Lesson/Lesson';
import Login from './pages/Login/Login';
import Test from './pages/Test/Test';
import Dnd from './pages/Test/Dnd';
import Weather from './pages/Test/Weather';
import LoadingBar from 'react-top-loading-bar';
import RedirectEmail from './pages/Redirect/RedirectEmail';
import RedirectCustomer from './pages/Redirect/RedirectCustomer';
import LessonSelect from './pages/LessonSelect/LessonSelect';
import ConfirmModal from './components/Shared/ConfirmModal/ConfirmModal';
import AdminSidebar from './components/Admin/AdminSidebar/AdminSidebar';
import Customers from './components/Admin/Customers/Customers';
import Courses from './components/Admin/Courses/Courses';
import Users from './components/Admin/Users/Users';
import AdminHome from './components/Admin/AdminHome/AdminHome';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutBase />,
    children: [
      { path: '/', element: <Lesson /> },
      { path: '/lessons', element: <LessonSelect /> },
    ],
  },
  {
    path: '/admin',
    element: <AdminSidebar />,
    children: [
      { path: '/admin', element: <AdminHome /> },
      { path: '/admin/customers', element: <Customers /> },
      { path: '/admin/courses', element: <Courses /> },
      { path: '/admin/users', element: <Users /> },
    ],
  },
  { path: '/:guidCustomer', element: <RedirectCustomer /> },
  { path: '/instancedcourse', element: <RedirectEmail /> },
  { path: '/login', element: <Login /> },
  { path: '/test', element: <Test /> },
  { path: '/dnd', element: <Dnd /> },
  { path: '/weather', element: <Weather /> },
  { path: '*', element: <PageNotFound /> },
]);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>

      <ConfirmModal />
      <Toaster position="bottom-center" />
      <LoadingBar
        color={'red'}
        progress={signalLoadingBar.value}
        onLoaderFinished={() => (signalLoadingBar.value = 0)}
      />
    </QueryClientProvider>
  );
};

export default App;
