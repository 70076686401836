import React, { useMemo, useRef, useState } from 'react';
import { useSignal } from '@preact/signals-react';
import { signalDarkMode } from '../../../../state/app';
import { Col, FloatingLabel, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { FRAGETYP } from '../../../../utils/constants';
import { EDITOR_CONFIG } from './editor-config';
import JoditEditor from 'jodit-react';
import CorrectAnswer from './CorrectAnswer/CorrectAnswer';
import ErrorBoundaryFallback from '../../../Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';
import QuestionMedia from '../QuestionMedia/QuestionMedia';
import QuestionCategories from '../QuestionCategories/QuestionCategories';
import { signalEditedQuestion } from '../../../../state/admin-courses';
import './style.scss';

const Question = () => {
  const editedObject = signalEditedQuestion.value;

  const objectType = useSignal(editedObject.type);

  const editor = useRef(null);
  const [editorHtml, setEditorHtml] = useState(editedObject.description);
  const editorConfig = useMemo(() => {
    return { ...EDITOR_CONFIG, theme: signalDarkMode.value ? 'dark' : null };
  }, []);

  const [answerMessage, setAnswerMessage] = useState(
    editedObject.answerMessage
  );
  const [correctAnswerMessage, setCorrectAnswerMessage] = useState(
    editedObject.correctAnswerMessage
  );
  const [wrongAnswerMessage, setWrongAnswerMessage] = useState(
    editedObject.wrongAnswerMessage
  );

  const handleEditorChange = (html) => {
    if (editedObject.description === html) return;

    editedObject.description = html;
    editedObject.isDirty = true;

    setEditorHtml(html);
  };

  const handleQuestionTypeChange = (e) => {
    const type = Number(e.target.value);
    editedObject.type = type;
    editedObject.possibleAnswers = [];
    editedObject.correctAnswer = [];
    editedObject.isDirty = true;

    objectType.value = type;
  };

  const handleAnswerMessage = (e) => {
    const message = e.target.value;
    editedObject.answerMessage = message;
    editedObject.isDirty = true;
    setAnswerMessage(message);
  };

  const handleCorrectAnswerMessage = (e) => {
    const message = e.target.value;
    editedObject.correctAnswerMessage = message;
    editedObject.isDirty = true;
    setCorrectAnswerMessage(message);
  };

  const handleWrongAnswerMessage = (e) => {
    const message = e.target.value;
    editedObject.wrongAnswerMessage = message;
    editedObject.isDirty = true;
    setWrongAnswerMessage(message);
  };

  return (
    <div className="component-question-container">
      <Row>
        <Col>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <JoditEditor
              ref={editor}
              value={editorHtml}
              config={editorConfig}
              tabIndex={1}
              onBlur={(newContent) => handleEditorChange(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </ErrorBoundary>
        </Col>
        <Col>
          <Tabs variant="tabs" className="mb-3">
            <Tab eventKey="question-type" title="Frågetyp">
              <div className="mb-3">
                {/* Question Type */}
                <Form.Select
                  onChange={handleQuestionTypeChange}
                  defaultValue={objectType.value}
                >
                  <option value={FRAGETYP.YESNO}>Ja / Nej</option>
                  <option value={FRAGETYP.NUMBER}>Nummer</option>
                  <option value={FRAGETYP.TEXT}>Text</option>
                  <option value={FRAGETYP.MULTI}>Multi</option>
                  <option value={FRAGETYP.INFORMATION}>Information</option>
                </Form.Select>
              </div>

              {objectType.value !== FRAGETYP.INFORMATION && (
                <>
                  {/* Correct / Possible Answers */}
                  <ErrorBoundary
                    FallbackComponent={ErrorBoundaryFallback}
                    onReset={() => {
                      // reset the state of your app so the error doesn't happen again
                    }}
                  >
                    <CorrectAnswer />
                  </ErrorBoundary>

                  {/* Answer Message */}
                  <FloatingLabel label="Meddelande vid svar" className="mt-3">
                    <Form.Control
                      type="text"
                      value={answerMessage || ''}
                      onChange={handleAnswerMessage}
                    />
                  </FloatingLabel>

                  {objectType.value !== FRAGETYP.TEXT && (
                    <>
                      {/* Correct Answer Message */}
                      <FloatingLabel
                        label="Meddelande vid rätt svar"
                        className="mt-3"
                      >
                        <Form.Control
                          type="text"
                          value={correctAnswerMessage || ''}
                          onChange={handleCorrectAnswerMessage}
                        />
                      </FloatingLabel>

                      {/* Wrong Answer Message */}
                      <FloatingLabel
                        label="Meddelande vid fel svar"
                        className="mt-3"
                      >
                        <Form.Control
                          type="text"
                          value={wrongAnswerMessage || ''}
                          onChange={handleWrongAnswerMessage}
                        />
                      </FloatingLabel>
                    </>
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="question-media" title="Media">
              <QuestionMedia />
            </Tab>
            <Tab eventKey="question-categories" title="Kategorier">
              <QuestionCategories />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Question;
