import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faUser } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const EditIcon = ({ onClick, icon = 'edit' }) => {
  return (
    <div className="edit-icon" onClick={onClick}>
      {icon === 'edit' && <FontAwesomeIcon icon={faPen} />}
      {icon === 'user' && <FontAwesomeIcon icon={faUser} />}
    </div>
  );
};

export default EditIcon;
