// Playground: https://xdsoft.net/jodit/play.html
// EXAMPLE: https://codesandbox.io/p/sandbox/react-vhnt8?file=%2Fsrc%2FApp.js%3A10%2C3-10%2C16

export const EDITOR_CONFIG = {
  readonly: false,
  height: '50vh',

  toolbarButtonSize: 'small',
  statusbar: false,
  showTooltipDelay: 0,
  toolbarAdaptive: false,
  buttons: [
    'bold',
    'italic',
    'underline',
    '|',
    'fontsize',
    'paragraph',
    // 'font',
    'brush',
    'symbols',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'superscript',
    'subscript',
    '|',
    'left',
    'center',
    'right',
    'justify',
    '|',
    'link',
    'image',
    '|',
    'hr',
    'table',
    '|',
    'undo',
    'redo',
    '|',
    'source',
    'fullsize',
    'preview',
  ],
  uploader: {
    insertImageAsBase64URI: true,
  },
};
