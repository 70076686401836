import React, { useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import UserModal from '../UserModal/UserModal';
import EditIcon from '../../Shared/EditIcon/EditIcon';
import { getUserById } from './utils';
import { userTemplate } from '../../../utils/admin/user.utils';
import AddButton from '../../Shared/AddButton/AddButton';

import './style.scss';
import {
  signalEditedUser,
  signalSelectedUserId,
  signalUsers,
} from '../../../state/admin-users';

const Users = () => {
  const [showUserModal, setShowUserModal] = useState(false);

  const handleModalClose = () => {
    setShowUserModal(false);
  };

  const handleEditUser = (e, userId) => {
    e.stopPropagation();
    signalEditedUser.value = { ...getUserById(userId) };
    setShowUserModal(true);
  };

  const handleNewUser = () => {
    signalEditedUser.value = { ...userTemplate };
    setShowUserModal(true);
  };

  return (
    <div>
      {signalUsers.value && (
        <>
          {/* User Modal */}
          {showUserModal && signalEditedUser.value && (
            <UserModal onModalClose={handleModalClose} />
          )}
          <Row>
            <Col>
              {/* Users */}
              <ListGroup variant="flush">
                {signalUsers.value.map(function (user, index) {
                  return (
                    <ListGroup.Item
                      key={index}
                      onClick={() => {
                        console.log('Selected User', user.id);
                        signalSelectedUserId.value = user.id;
                      }}
                      onDoubleClick={(e) => {
                        handleEditUser(e, user.id);
                      }}
                      action
                      active={signalSelectedUserId.value === user.id}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{user.name}</span>

                        {/* Edit User */}
                        <EditIcon onClick={(e) => handleEditUser(e, user.id)} />
                      </div>
                    </ListGroup.Item>
                  );
                })}

                {/* Add User */}
                <AddButton onClick={handleNewUser} />
              </ListGroup>
            </Col>
            {/* <Col>
              {state.selectedUserId.value && (
                <ListGroup
                  variant="flush"
                >
                  {getUserCourses(selectedUser.value).map(function (
                    course,
                    index
                  ) {
                    return (
                      <ListGroup.Item
                        key={index}
                        onClick={() => {
                          selectedCourse.value = course;
                          selectedLesson.value = null;
                        }}
                        action
                        active={selectedCourse.value?.id === course.id}
                      >
                        {course.description}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </Col> */}
          </Row>
        </>
      )}
    </div>
  );
};

export default Users;
