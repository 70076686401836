// https://www.jeffedmondson.dev/blog/react-protected-routes/

import React, { useState, createContext, useContext } from 'react';

// Create the context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Get the value from session storage.
  const sessionStorageValue = JSON.parse(sessionStorage.getItem('loggedIn'));
  // Use this value as the defalt value for the state
  const [authed, setAuthed] = useState(sessionStorageValue);

  const login = async () => {
    const result = await fakeAsyncLogin();

    if (result) {
      console.log('The user has logged in');

      setAuthed(true);
      sessionStorage.setItem('loggedIn', 'true');
    }
  };

  const logout = async () => {
    const result = await fakeAsyncLogout();

    if (result) {
      console.log('The user has logged out');
      setAuthed(false);
      sessionStorage.setItem('loggedIn', 'false');
    }
  };

  /// Mock Async Login API call.
  // TODO: Replace with your actual login API Call code
  const fakeAsyncLogin = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('Logged In');
      }, 300);
    });
  };

  // Mock Async Logout API call.
  // TODO: Replace with your actual logout API Call code
  const fakeAsyncLogout = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('Logged out');
      }, 300);
    });
  };

  return (
    // Using the provider so that ANY component in our application can
    // use the values that we are sending.
    <AuthContext.Provider value={{ authed, setAuthed, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);
