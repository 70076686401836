import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { signalEditedQuestion } from '../../../../state/admin-courses';
import {
  getAllCategoriesAsObject,
  getSelectedCategoriesAsObject,
} from '../../Courses/utils';

import './style.scss';

const QuestionCategories = () => {
  const editedObject = signalEditedQuestion.value;

  const [value, setValue] = useState(getSelectedCategoriesAsObject());

  const allCategories = getAllCategoriesAsObject();

  const handleChange = (newValue) => {
    setValue(newValue);
    editedObject.categories = newValue.map((obj) => obj.label);
    editedObject.isDirty = true;
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      options={allCategories}
      value={value}
      closeMenuOnSelect={false}
      onChange={(newValue) => handleChange(newValue)}
      className="react-select"
      placeholder="Kategorier"
    />
  );
};

export default QuestionCategories;
