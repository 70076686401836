import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useGetFonts } from './useGetFonts';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { loadFont } from '../../../../../utils/font.utils';
import CopyToClipboardButton from '../../../../Shared/CopyToClipboardButton/CopyToClipboardButton';
import {
  downloadBase64,
  getFileExtension,
  getFilenameWithoutExtension,
} from '../../../../../utils/files.utils';
import { signalEditedCustomer } from '../../../../../state/admin-customers';
import './style.scss';

const FontList = () => {
  const editedObject = signalEditedCustomer.value;

  const metadata = editedObject.metaData.font;
  const queryClient = useQueryClient();
  const queries = useGetFonts(metadata.externalResourceId);

  const handleFontDownload = (font) => {
    const dataUrl = `data:font/${getFileExtension(font.name)};base64,${
      font.content
    }`;

    downloadBase64(dataUrl, font.name);
  };

  const handleFontRemove = async (font) => {
    if (!metadata.deleteExternalResourceId)
      metadata.deleteExternalResourceId = [];

    metadata.deleteExternalResourceId.push(font.id);
    metadata.externalResourceId = metadata.externalResourceId.filter(
      (id) => id !== font.id
    );

    editedObject.isDirty = true;

    queryClient.invalidateQueries({ queryKey: ['admin-customer-font'] });
  };

  const isLoading = queries.some((query) => query.isLoading);
  const isSuccess = queries.every((query) => query.isSuccess);

  useEffect(() => {
    if (!isSuccess) return;

    async function loadFonts() {
      for (const query of queries) {
        const font = query.data;
        const fontFamily = await loadFont(font.name, font.content);

        document.getElementById(font.id).style.fontFamily = fontFamily;
      }
    }

    loadFonts();
  }, [isSuccess, queries]);

  if (isSuccess) {
    return (
      <div className="component-font-list">
        {queries.map((font, index) => (
          <div className="grid-container" key={index}>
            <div className="grid-item">
              <div className="d-flex align-items-center">
                <CopyToClipboardButton
                  className="me-2"
                  text={`font-family: '${getFilenameWithoutExtension(
                    font.data.name
                  )}';`}
                />

                <code className="font-name">
                  {getFilenameWithoutExtension(font.data.name)}
                </code>
              </div>
            </div>

            <div className="grid-item text-start">
              <span id={font.data.id} className="font-preview ">
                Det här är en förhandsgranskning av fonten
              </span>
            </div>

            <div className="grid-item">
              {/* Download */}
              <Button
                variant="primary"
                className="ms-2"
                onClick={() => handleFontDownload(font.data)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>

              {/* Remove */}
              <Button
                variant="danger"
                className="ms-2"
                onClick={() => handleFontRemove(font.data)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (isLoading) {
    return <Spinner animation="border" />;
  }
};

export default FontList;
