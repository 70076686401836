import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApiGet } from '../../hooks/useApiGet';
import {
  getInstancedCourse,
  loadExternalResources,
} from '../../utils/home/customer';

function RedirectEmail() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const guidCourse = searchParams.get('id');
  const guidUser = searchParams.get('userid');
  const guidCustomer = searchParams.get('customerid');

  const { data, error, isDone } = useApiGet(
    `customer/${guidCustomer}/?binaries=true`
  );

  const load = async (customer) => {
    await loadExternalResources(customer);

    const instancedCourse = await getInstancedCourse(guidCourse);

    console.log(`Courses loaded`, instancedCourse);

    navigate(`/lessons`, {
      replace: true,
      state: {
        customerId: guidCustomer,
        userId: guidUser,
        courses: [instancedCourse],
      },
    });
  };

  if (isDone && !error) {
    load(data);
  }

  return <>{!isDone && !error && <div>Loading...</div>}</>;
}

export default RedirectEmail;
