import './style.scss';

export default function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  // var style = ['border: 1px solid black; padding:5px;'].join(';');
  // console.log('%cErrorBoundaryFallback', style);
  // console.log(error);

  return (
    <div className="component-errorbounary">
      <p>Nu gick något snett:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Försök igen</button>
    </div>
  );
}
