import { signalEditedCustomer } from '../../../state/admin-customers';
import { apiClient, handleApiError } from '../../../utils/api';

export async function saveCustomerMetadata() {
  const edit = signalEditedCustomer.value;

  for (const key in edit.metaData) {
    if (edit.metaData.hasOwnProperty(key)) {
      const property = edit.metaData[key];
      let url;

      //console.log('metadata property', property);

      // Delete
      if (property.content === null && property.externalResourceId) {
        try {
          await deleteExternalResource(property.externalResourceId);
          //   console.log('metadata (delete)', property);
          property.externalResourceId = null;
        } catch (error) {
          handleApiError(error, `Fel vid metadata DELETE`);
        }
      } else if (Array.isArray(property.deleteExternalResourceId)) {
        // Delete (array)
        try {
          for (const id of property.deleteExternalResourceId) {
            await deleteExternalResource(id);
            // console.log('metadata (deleteExternalResourceId)', property);
          }

          property.externalResourceId = property.externalResourceId.filter(
            (id) => !property.deleteExternalResourceId.includes(id)
          );
        } catch (error) {
          handleApiError(error, `Fel vid metadata DELETE Array`);
        }
      } else if (property.content && !property.externalResourceId) {
        // Create
        url = `customer/${edit.id}/externalresources`;

        try {
          const body = {
            name: property.name || key,
            type: property.type,
            content: property.content,
          };

          // console.log('metadata create', url, body);

          const result = await apiClient.post(url, body);

          console.log('metadata result (create)', result.data);

          property.externalResourceId = result.data.id;
        } catch (error) {
          handleApiError(error, `Fel vid metadata CREATE: ${url}`);
        }
      } else if (
        property.content &&
        Array.isArray(property.externalResourceId)
      ) {
        // Create (array)
        url = `customer/${edit.id}/externalresources`;

        try {
          const body = {
            name: property.name || key,
            type: property.type,
            content: property.content,
          };

          console.log('metadata create-array', url, body);

          const result = await apiClient.post(url, body);

          console.log('metadata result (create-array)', result.data);

          property.externalResourceId.push(result.data.id);
        } catch (error) {
          handleApiError(error, `Fel vid metadata CREATE-ARRAY: ${url}`);
        }
      } else if (property.content && property.externalResourceId) {
        // Update
        url = `/externalresources/${property.externalResourceId}`;

        try {
          const body = {
            name: property.name || key,
            nameChanged: true,
            content: property.content,
            contentChanged: true,
          };

          const result = await apiClient.patch(url, body);

          console.log('metadata result (update)', result.data);
        } catch (error) {
          handleApiError(error, `Fel vid metadata UPDATE: ${url}`);
        }
      }

      // Remove helper properties before saving, they are not needed in "metaData"
      delete property.content;
      delete property.name;
      delete property.isLoaded;
      delete property.deleteExternalResourceId;
    }
  }
}

export async function getExternalResource(id) {
  try {
    // console.log('loading external resource', id);
    const result = await apiClient.get(`/externalresources/${id}`);
    //console.log('external resource', result.data);
    return result.data;
  } catch (error) {
    handleApiError(error, `Kunde inte hitta externalresource: ${id}`);
  }
}

export async function deleteExternalResource(id) {
  try {
    await apiClient.delete(`/externalresources/${id}`);
  } catch (error) {
    handleApiError(error, `Kunde radera externalresource: ${id}`);
  }
}
